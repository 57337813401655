import React from "react";
import { Button } from "reactstrap";

import { default as utilIcons } from "../resources/utilIcons";
import { colors } from "../styles/colors";

function BackButton({ onClick }) {
    return (
        <Button className="back-button" onClick={onClick}>
            {utilIcons.leftArrow(colors["ars-neutral-900"], {
                height: "1em",
                width: "1em",
            })}
        </Button>
    );
}

export default BackButton;
