const origin = window.location.origin;
const HOST = origin.match(/:/g).length > 1 ? origin.substring(0, origin.lastIndexOf(':')) : origin;
const PORT = HOST === 'https://dataview.air-resource.com' ? '' : ':8080';
export const SERVER_URL = HOST + PORT;
export const AUTH_URL = SERVER_URL + '/api/auth';
export const SITE_URL = SERVER_URL + '/api/site';
export const DOC_URL = SERVER_URL + '/api/doc';
export const INV_URL = SERVER_URL + '/api/inv';
export const EMAIL_URL = SERVER_URL + '/api/email';
export const ADMIN_URL = SERVER_URL + '/api/admin';
export const STATIONLOG_URL = SERVER_URL + '/api/stationLog';
export const DASHBOARD_URL = SERVER_URL + '/api/dashboard';
export const RENDER_URL = SERVER_URL + '/api/render';
export const CHECKLIST_URL = SERVER_URL + '/api/checklist';
export const UTIL_URL = SERVER_URL + '/api/util';

export const CONTACT_ADDRESS = 'dataview@air-resource.com';

export const corsConfig = {
    origin: origin,
    withCredentials: true,
    
};