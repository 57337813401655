import React, { useState } from 'react';

import '../styles/DesktopSiteView.css';
import '../styles/siteDetails.css';

import { Nav, NavItem, NavLink } from 'reactstrap';
import SiteMap from '../components/SiteMap';
import DocumentView from './DocumentView';
import SiteInventory from './SiteInventory';
import SiteContacts from './SiteContacts';

export default function DesktopSiteView({site, user}) {
    const [activeTab, setActiveTab] = useState('docs');

    const changeTab = (event => {
        setActiveTab(event.target.getAttribute('tab-id'));
    });

    const getTabContent = () => {
        switch (activeTab) {
            case 'docs':
                return <DocumentView site={site} user={user} />
            case 'inv':
                return <SiteInventory site={site} />
            case 'contacts':
                return <SiteContacts selectedSite={site} />
            default:
                return null;
        }
    }

    const getSiteName = () => {
        if (site === undefined) return null;
        return site.name + ((site.name2 === undefined || site.name2 === null || site.name2 === '') ? '' : ' - ' + site.name2);
    }

    const getSiteAbbr = () => {
        if (site === undefined) return null;
        return site.aqmetAbbr;
    }

    return (
        <div id='desktop-site-view'>
            <SiteMap site={site} />
            <div id='site-details-info-wrapper'>
                <span id='site-details-sitename'>{getSiteName()}</span>
                <span id='site-details-siteabbr'>{getSiteAbbr()}</span>
                <Nav id='desktop-site-view-tabs' tabs>
                    <NavItem className='site-tab' id='site-tab-docs'>
                        <NavLink tab-id='docs' active={activeTab === 'docs'} onClick={changeTab}>Documents</NavLink>
                    </NavItem>
                    <NavItem className='site-tab' id='site-tab-inv'>
                        <NavLink tab-id='inv' active={activeTab === 'inv'} onClick={changeTab}>Inventory</NavLink>
                    </NavItem>
                    <NavItem className='site-tab' id='site-tab-contacts'>
                        <NavLink tab-id='contacts' active={activeTab === 'contacts'} onClick={changeTab}>Contacts</NavLink>
                    </NavItem>
                </Nav>
                <div id='desktop-site-view-content'>
                    {getTabContent()}
                </div>
            </div>
        </div>
    )
}