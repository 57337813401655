import React, { useEffect, useRef, useState } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM.js';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { fromLonLat } from 'ol/proj'
import { MouseWheelZoom, defaults } from 'ol/interaction'
import { defaults as defaultControls } from 'ol/control.js';
import { Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Clock from "react-live-clock";

import 'ol/ol.css';
import '../styles/siteMap.css';
import { colors } from '../styles/colors';
import { marker } from '../resources/marker';
import { default as markerIcon } from '../resources/marker-svgrepo-com.svg';

import { getTimezoneAbbr, getTimezoneName } from '../util/timeUtils';


function SiteMap({site}) {
    const mapTargetElement = useRef(null);
    const [map, setMap] = useState(null);
    const [siteHasCoords, setSiteHasCoords] = useState(false);

    useEffect(() => {
        const newMap = new Map({
            interactions: defaults({ dragPan: false, mouseWheelZoom: false, pinchRotate: false, pinchZoom: false }).extend([
                new MouseWheelZoom({
                    useAnchor: false
                })
            ]),
            controls: defaultControls({ zoom: true, rotate: false, attribution: false }),
            layers: [
                new TileLayer({ source: new OSM() }),
            ],
            view: new View({
                center: [-105.084419, 40.585258],
                projection: 'EPSG:4326',
                zoom: 14,
                minZoom: 6,
                maxZoom: 18,
            }),
        })

        const featuresLayer = new VectorLayer({
            source: new VectorSource({
                features: [new Feature({
                    geometry: new Point(fromLonLat([-105.084419, 40.585258], 'EPSG:4326'))
                })]
            }),
            style: new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    src: markerIcon
                })
            })
        });
        newMap.addLayer(featuresLayer);
        newMap.setTarget(mapTargetElement.current || "");
        setMap(newMap);
        return () => newMap.setTarget("");
    }, [])

    useEffect(() => {
        if (site !== undefined && map !== null) {
            if (site.latitude !== null && site.longitude !== null) {
                changeViewCenter([site.longitude, site.latitude]);
                changeFeatureGeometry([site.longitude, site.latitude]);
                setSiteHasCoords(true);
            } else {
                setSiteHasCoords(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site, map]);

    const changeViewCenter = (coordinates) => {
        map.getView().setCenter(coordinates);
    }

    const changeFeatureGeometry = (coordinates) => {
        map.getLayers().array_[1].getSource().getFeatures()[0].setGeometry(new Point(fromLonLat(coordinates, 'EPSG:4326')));
    }

    const getCoordinateString = () => {
        if (site === undefined) return null;
        const latDirection = site.latitude >= 0 ? '°N' : '°S';
        const lonDirection = site.longitude >= 0 ? '°E' : '°W';
        return <div id='site-map-caption-latlong'>{`${Math.abs(site.latitude) + latDirection}, ${Math.abs(site.longitude) + lonDirection}`}</div>;
    }

    const getElevationString = () => {
        if (site === undefined) return null;
        return <div id='site-map-caption-elevation'>Elevation: {site.elevation === undefined ? 'N/A' : site.elevation + 'ft'}</div>;
    }

    const getTimezoneString = () => {
        if (site === undefined) return null;
        return <div id='site-map-caption-timezone'>Timezone: {site.timezone === undefined ? 'N/A' : (getTimezoneName(site.timezone) + ` (${getTimezoneAbbr(site.timezone)})`)}</div>;
    }

    return (
        <div id='site-details-map-wrapper'>
            <div
                ref={mapTargetElement}
                id="site-map"
                hidden={!siteHasCoords}
            />
            <div id='site-map-caption' hidden={!siteHasCoords}>
                {getCoordinateString()}
                {getElevationString()}
                {getTimezoneString()}
                <div id='site-map-caption-localtime'>Local Time: <Clock ticking timezone={site.timezone} format='h:mm A'/></div>
            </div>
            <MapMissing hidden={siteHasCoords} />
        </div>
    )

}

function MapMissing({hidden}) {
    return (
        <div id='map-missing' hidden={hidden}>
            {marker(colors['ars-neutral-500'])}
            <div>No geo-coordinate data found for this site.</div>
        </div>
    )
}

export default SiteMap;