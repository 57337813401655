import React, { useState } from "react";
import Signup from "./Signup";
import Login from "./Login";
import { ForgotPassword } from "./ForgotPassword";

function AuthLanding({ setInvalid, invalid, handleLogin, waiting, setWaiting }) {
    const [signup, setSignup] = useState(false);
    const [forgotModal, setForgotModal] = useState(false);

    const toggleSignup = () => setSignup(!signup);

    const toggleForgotModal = () => setForgotModal(!forgotModal);

    const getRenderedContent = () => {
        if (signup) {
            return <Signup
                toggleSignup={toggleSignup}
            />
        } else {
            return (
                <Login
                    setInvalid={setInvalid}
                    invalid={invalid}
                    handleLogin={handleLogin}
                    waiting={waiting}
                    setWaiting={setWaiting}
                    toggleSignup={toggleSignup}
                    toggleForgotModal={toggleForgotModal}
                />
            )
        }
    }

    return (
        <div id='auth-page'>
            {getRenderedContent()}
            <ForgotPassword isOpen={forgotModal} toggle={toggleForgotModal} />
        </div>
    )
}

export default AuthLanding;