import React, { useContext, useEffect, useState } from "react";

import '../styles/SiteContacts.css';

import { SITE_URL } from "../serverConfig";
import { RestContext } from "../App";

const opTypes = {
    "Primary Contact": 1,
    "Site Operator": 2,
    "Secondary Site Operator": 3
}

function SiteContacts({ selectedSite }) {
    const [contacts, setContacts] = useState([]);

    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite && selectedSite.idNo !== 'all') {
            sendGetRequest(SITE_URL + '/contacts/' + selectedSite.idNo, (response) => {
                setContacts(response.data.sort(sortContacts));
            });
        }
    }, [selectedSite]);

    const sortContacts = (a, b) => {
        if (opTypes[a.opType] < opTypes[b.opType]) return -1;
        else if (opTypes[a.opType] > opTypes[b.opType]) return 1;
        else {
            if (a.admin && !b.admin) return -1;
            else if (b.admin && !a.admin) return 1;
            else {
                return a.name.localeCompare(b.name);
            }
        }
    }

    const getAvailableContactTypes = (contact) => {
        const contactTypes = {
            'Email': formatEmail(contact.email),
            'Office': formatPhone(contact.office),
            'Cell': formatPhone(contact.cell),
            'Notes': contact.notes
        };

        return Object.keys(contactTypes).map((type) => {
            if (!contactTypes[type] || contactTypes[type] === '') return null;
            return (
                <div className="site-contacts-table-row-contact">
                    <span className="site-contacts-table-row-contact-type">{type + ':'}</span>
                    <span className="site-contacts-table-row-contact-value">{contactTypes[type]}</span>
                </div>
            )
        })
    }

    const formatEmail = (email) => {
        if (!email) return null;
        return (
            <a href={"mailto:" + email}>{email}</a>
        )
    }

    const formatPhone = (phone) => {
        if (!phone) return null;
        const phoneString = phone.replace(/[\.]/g, '-');
        const callable = phoneString.replace(' ', '').replace('x', ',');
        return (
            <a href={"tel:" + callable}>{phoneString}</a>
        )
    }

    const getPrimary = () => {
        const primaryContacts = contacts.filter(contact => contact.opType === 'Primary Contact');
        if (primaryContacts.length === 0) return null;
        else {
            const sectionHeader = (
                <tr className="site-contacts-table-section-header">
                    <td colSpan={2}>Primary Contacts</td>
                </tr>
            );
            const rows = [sectionHeader];
            primaryContacts.forEach(contact => {
                const row = (
                    <tr className="site-contacts-table-row">
                        <td className="site-contacts-table-row-name">{contact.name + (contact.admin ? '*' : '')}</td>
                        <td className="site-contacts-table-row-info">
                            {getAvailableContactTypes(contact)}
                        </td>
                    </tr>
                );
                rows.push(row);
            });
            return rows;
        }
    }

    const getSiteOps = () => {
        const primaryContacts = contacts.filter(contact => contact.opType === 'Site Operator');
        if (primaryContacts.length === 0) return null;
        else {
            const sectionHeader = (
                <tr className="site-contacts-table-section-header">
                    <td colSpan={2}>Site Operators</td>
                </tr>
            );
            const rows = [sectionHeader];
            primaryContacts.forEach(contact => {
                const row = (
                    <tr className="site-contacts-table-row">
                        <td className="site-contacts-table-row-name">{contact.name + (contact.admin ? '*' : '')}</td>
                        <td className="site-contacts-table-row-info">
                            {getAvailableContactTypes(contact)}
                        </td>
                    </tr>
                );
                rows.push(row);
            });
            return rows;
        }
    }

    const getSecondaryOps = () => {
        const primaryContacts = contacts.filter(contact => contact.opType === 'Secondary Site Operator');
        if (primaryContacts.length === 0) return null;
        else {
            const sectionHeader = (
                <tr className="site-contacts-table-section-header">
                    <td colSpan={2}>Secondary Site Operators</td>
                </tr>
            );
            const rows = [sectionHeader];
            primaryContacts.forEach(contact => {
                const row = (
                    <tr className="site-contacts-table-row">
                        <td className="site-contacts-table-row-name">{contact.name + (contact.admin ? '*' : '')}</td>
                        <td className="site-contacts-table-row-info">
                            {getAvailableContactTypes(contact)}
                        </td>
                    </tr>
                );
                rows.push(row);
            });
            return rows;
        }
    }

    return (
        <div id='site-contacts'>
            <table>
                <tbody>
                    {getPrimary()}
                    {getSiteOps()}
                    {getSecondaryOps()}
                    {!contacts || contacts.length === 0 ? <NoContacts /> : null}
                </tbody>
            </table>
            <div id='site-contacts-legend'>
                <div hidden={!contacts.some(contact => contact.admin)}>* Adminstrative Contact</div>
            </div>
        </div>
    )
}

function NoContacts() {
    return (
        <tr id='site-contacts-no-contacts'>
            <td colSpan={2}>No contacts found.</td>
        </tr>
    )
}

export default SiteContacts;