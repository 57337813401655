import React, { useContext, useEffect, useState } from "react";
import { Button, Label, Spinner } from "reactstrap";

import { RestContext } from "../../../App";
import { CHECKLIST_URL, INV_URL } from "../../../serverConfig";
import SearchableSelect from "../../../components/SearchableSelect";
import MultiSelect from "../../../components/MultiSelect";

function ChecklistInstrumentSubtypeAdmin(props) {
    const [instrumentModels, setInstrumentModels] = useState([]);
    const [instrumentTypes, setInstrumentTypes] = useState([]);
    const [selectedInstrument, setSelectedInstrument] = useState();
    const [selectedSubtypes, setSelectedSubtypes] = useState([]);
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        sendGetRequest(INV_URL + "/instruments", (response) => {
            setInstrumentModels(response.data);
        });
        sendGetRequest(INV_URL + "/types", (response) => {
            setInstrumentTypes(response.data);
        });
    }, []);
    
    useEffect(() => {
        if (selectedInstrument) {
            sendGetRequest(
                CHECKLIST_URL + "/subtypes/" + selectedInstrument,
                (response) => {
                    setSelectedSubtypes(response.data);
                }
            );
        } else {
            setSelectedSubtypes([]);
        }
    }, [selectedInstrument]);

    const handleChange = (event) => {
        setChanged(true);
    };

    const handleSubmit = (event) => {
        setWaiting(true);

        const payload = {
            instrumentId: selectedInstrument,
            subtypes: selectedSubtypes,
        };

        sendPostRequest(
            CHECKLIST_URL + "/subtypes",
            payload,
            (response) => {
                sendGetRequest(
                    CHECKLIST_URL + "/subtypes/" + selectedInstrument,
                    (response) => {
                        setSelectedSubtypes(response.data);
                        setChanged(false);
                        setWaiting(false);
                    }
                );
            },
            (response) => {
                setWaiting(false);
            }
        );
    };

    const getSelectedInstrumentType = () => {
        if (!selectedInstrument || !instrumentModels || instrumentModels.length === 0) {
            return "N/A";
        }

        const instrument = instrumentModels.find(instrument => instrument.instrumentId === selectedInstrument);
        if (!instrument) {
            return "N/A";
        } else {
            return instrument.instrumentType;
        }
    }

    const subtypeIsNotInstrumentType = (subtype) => {
        if (!selectedInstrument || !instrumentModels || instrumentModels.length === 0) {
            return true;
        }

        const instrument = instrumentModels.find(instrument => instrument.instrumentId === selectedInstrument);
        if (!instrument) {
            return true;
        } else {
            return subtype !== instrument.instrumentType;
        }
    }

    return (
        <div id="checklist-instrument-subtype-admin">
            <div className="checklist-admin-section-select-wrapper">
                <Label for="instrument-subtype-select">Instrument:</Label>
                <SearchableSelect
                    name="instrument-subtype-select"
                    data={instrumentModels}
                    selected={selectedInstrument}
                    setSelected={setSelectedInstrument}
                    valField={"instrumentId"}
                    textField={"instrumentName"}
                    groupField={"instrumentType"}
                />
                <Label>Type:</Label>
                <div id='checklist-instrument-subtype-admin-selected-instrument-type'>{getSelectedInstrumentType()}</div>
            </div>
            <MultiSelect
                data={instrumentTypes}
                selected={selectedSubtypes}
                setSelected={setSelectedSubtypes}
                onChange={handleChange}
                filterFn={subtypeIsNotInstrumentType}
            />
            <Button
                className="submit-button"
                onClick={handleSubmit}
                disabled={waiting || !changed}
            >
                {waiting ? <Spinner size="sm" /> : "Save"}
            </Button>
        </div>
    );
}

export default ChecklistInstrumentSubtypeAdmin;
