import React, { useContext, useEffect, useState } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button, Spinner } from "reactstrap";

import { colors } from "../../styles/colors";
import { default as utilIcons } from "../../resources/utilIcons";

import { DASHBOARD_URL } from "../../serverConfig";
import { RestContext } from "../../App";
import DashboardChart from "./DashboardChart";
import { sortParameters } from "../../util/dashboardUtil";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController,
    zoomPlugin
);

ChartJS.defaults.font.family = "'Roboto', sans-serif";
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = "normal";

function Visuals({ selectedSite, metric }) {
    const [timespan, setTimespan] = useState(7); // Number of days of data
    const [responseData, setResponseData] = useState();
    const [openChart, setOpenChart] = useState();
    const [charts, setCharts] = useState();
    const [loading, setLoading] = useState(false);

    const { sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite !== undefined && selectedSite !== "all") {
            setOpenChart(0);
            loadData();
        }
    }, [selectedSite]);

    useEffect(() => {
        if (selectedSite !== undefined && selectedSite !== "all") {
            loadData();
        }
    }, [metric, timespan]);

    useEffect(() => {
        const newCharts = mapResponseDataToChart();
        setCharts(newCharts);
    }, [responseData]);

    useEffect(() => {
        if (charts) {
            setLoading(false);
        }
    }, [charts]);

    const loadData = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("numDays", timespan);
        formData.append("metric", metric);
        sendPostRequest(
            DASHBOARD_URL + "/recentData/" + selectedSite,
            formData,
            (response) => {
                if (response.data && response.data.length > 0) {
                    setResponseData(response.data.sort(sortResponse));
                } else {
                    setOpenChart();
                    setResponseData();
                    setLoading(false);
                }
            },
            (error) => {
                setOpenChart();
                setResponseData();
                setLoading(false);
            }
        );
    };

    const sortResponse = (a, b) => {
        return sortParameters(a.parameter, b.parameter);
    };

    const mapResponseDataToChart = () => {
        if (responseData === undefined) return undefined;
        return responseData.map((parameter, index) => (
            <DashboardChart
                key={"dashboard-chart-" + index}
                parameter={parameter}
            />
        ));
    };

    const mapResponseDataToOption = () => {
        if (responseData === undefined) return undefined;
        return responseData.map((parameter, index) => (
            <option key={"visuals-parameter-" + index} value={index}>
                {parameter.parameter}
            </option>
        ));
    };

    const handleSelect = (event) => {
        const value = event.target.value;
        setOpenChart(value);
    };

    const handleTimespan = (event) => {
        const value = event.target.value;
        setTimespan(value);
    };

    const getCurrentChart = () => {
        if (charts && openChart !== undefined) {
            return charts[openChart];
        }
    };

    const getLoading = () => {
        if (loading) {
            return <VisualsLoading />;
        }
    };

    const checkNoData = () => {
        return !(responseData && charts && openChart !== undefined);
    };

    return (
        <div id="dashboard-visuals" className="dashboard-widget">
            {getLoading()}
            <div id="dashboard-visuals-wrapper">
                <div id="dashboard-visuals-header">
                    <div
                        id="dashboard-visuals-title"
                        className="dashboard-widget-title"
                    >
                        Visuals
                    </div>
                    <div
                        className="dashboard-visuals-select-wrapper"
                        hidden={checkNoData()}
                    >
                        <select
                            id="dashboard-visuals-select"
                            name="parameter-select"
                            onInput={handleSelect}
                            value={openChart}
                        >
                            {mapResponseDataToOption()}
                        </select>
                    </div>
                    <div
                        className="dashboard-visuals-select-wrapper"
                        hidden={checkNoData()}
                    >
                        <select
                            id="dashboard-visuals-timespan-select"
                            value={timespan}
                            onInput={handleTimespan}
                        >
                            <option value={7}>7 days</option>
                            <option value={14}>14 days</option>
                            <option value={21}>21 days</option>
                            <option value={30}>30 days</option>
                        </select>
                    </div>
                    <Button
                        id="dashboard-visuals-refresh"
                        className="dashboard-widget-refresh"
                        onClick={loadData}
                        hidden={checkNoData()}
                    >
                        {utilIcons["refresh"](colors["ars-neutral-400"])}
                    </Button>
                </div>
                <NoData hidden={!checkNoData()} />
                {getCurrentChart()}
            </div>
        </div>
    );
}

function VisualsLoading() {
    return (
        <div
            id="dashboard-visuals-loading"
            className="dashboard-widget-loading"
        >
            <Spinner color="light" />
        </div>
    );
}

function NoData({ hidden }) {
    return (
        <div
            id="dashboard-visuals-no-data"
            className="dashboard-no-data"
            hidden={hidden}
        >
            No data available.
        </div>
    );
}

export default Visuals;
