import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";

import { colors } from "../../styles/colors";

import { getUnitString } from "../../util/dashboardUtil";
import { getAQIRating } from "./AQI";
import { checkMobile } from "../../util/globals";
import { DashboardContext } from "./Dashboard";

function DashboardChart({ parameter }) {
    const [options, setOptions] = useState({});
    const [data, setData] = useState({});

    const { aqiColors, getAqiRange } = useContext(DashboardContext);

    useEffect(() => {
        if (parameter !== undefined) {
            generateDataObject(parameter);
            generateOptionsObject(parameter);
        }
    }, [parameter]);

    const generateDataObject = (parameter) => {
        const startDate = new Date(parameter.startDate).getTime();
        let labels;
        if (parameter.fields.length !== 0) {
            labels = parameter.fields[0].data.map((val, index) => {
                const tickDate = new Date(startDate + index * (1000 * 60 * 60));
                return tickDate.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                });
            });
        } else {
            labels = parameter.aqi.data.map((val, index) => {
                const tickDate = new Date(startDate + index * (1000 * 60 * 60));
                return tickDate.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                });
            });
        }

        const dataObj = {
            labels,
            datasets: [],
        };

        const lineColors = [
            colors["ars-neutral-200"],
            colors["ars-blue-400"],
            colors["ars-blue-700"],
            colors["ars-blue-900"],
        ];

        for (let index in parameter.fields) {
            const line = {
                type: "line",
                label: parameter.fields[index].fieldName,
                borderColor: lineColors[index],
                borderWidth: 4,
                backgroundColor: "transparent",
                fill: false,
                data: parameter.fields[index].data.map((val) =>
                    val < 0 ? null : val
                ),
                xAxisId: "x",
                yAxisID: "y",
                tension: 0.4,
            };

            dataObj.datasets.push(line);
        }

        if (parameter.aqi !== null) {
            const bar = {
                type: "bar",
                label: parameter.aqi.fieldName,
                backgroundColor: parameter.aqi.data.map((val) =>
                    val < 0
                        ? "transparent"
                        : "#" +
                          aqiColors[
                              getAQIRating(
                                  val,
                                  getAqiRange(parameter.aqi.aqiParameter)
                              ).index
                          ]
                ),
                data: parameter.aqi.data.map((val) => (val < 0 ? null : val)),
                xAxisId: "x",
                borderColor: "transparent",
                borderWidth: 0,
                yAxisID: "y1",
            };

            dataObj.datasets.push(bar);
        }

        setData(dataObj);
    };

    const generateOptionsObject = (parameter) => {
        const optionsObj = {
            responsive: true,
            interaction: {
                mode: "index",
                intersect: false,
            },
            plugins: {
                legend: {
                    display:
                        parameter.aqi !== null || parameter.fields.length > 1,
                    position: "bottom",
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: "x",
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true,
                        },
                        mode: "x",
                    },
                },
            },
            scales: {
                x: {
                    min: 0,
                    max: checkMobile() ? (window.innerWidth - 40) / 32 : 128,
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
            },
            maintainAspectRatio: false,
        };

        const maxY = getMaxYValue(parameter);
        const minY = getMinYValue(parameter);
        const yAxis = {
            title: {
                display: true,
                align: "center",
                text: getUnitString(parameter),
            },
            type: "linear",
            display: true,
            position: "left",
            min: minY,
            max: maxY,
        };

        optionsObj.scales["y"] = yAxis;

        if (parameter.aqi !== null) {
            const y1Axis = {
                title: {
                    display: true,
                    align: "center",
                    text: "NowCast AQI",
                },
                type: "linear",
                display: true,
                position: "right",
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                max: getMaxAQIValue(parameter),
            };

            optionsObj.scales["y1"] = y1Axis;
        }

        setOptions(optionsObj);
    };

    const getMaxYValue = (parameter) => {
        if (parameter.fields.length !== 0) {
            switch (parameter.fields[0].unit) {
                case "%":
                    return 100;
                case "°":
                    return 360;
                default:
                    break;
            }
        } else {
            switch (parameter.aqi.unit) {
                case "%":
                    return 100;
                case "°":
                    return 360;
                default:
                    break;
            }
        }
        

        return Math.max(
            Math.max(
                ...parameter.fields.map((field) => Math.max(...field.data))
            ) * 1.2,
            1
        );
    };

    const getMaxAQIValue = (parameter) => {
        return Math.max(...parameter.aqi.data) * 1.2;
    };

    const getMinYValue = (parameter) => {
        return Math.min(
            ...parameter.fields.map((field) =>
                Math.min(...field.data.map((val) => (val < 0 ? null : val)))
            ),
            0
        );
    };

    const getChart = () => {
        if (
            Object.keys(data).length === 0 ||
            Object.keys(options).length === 0
        ) {
            return null;
        } else {
            return <Chart type="bar" data={data} options={options} />;
        }
    };

    return <div className="dashboard-chart">{getChart()}</div>;
}

export default DashboardChart;
