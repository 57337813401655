import React, { useContext, useEffect, useState } from 'react';

import '../styles/DocumentView.css';
import { default as downloadIcon } from '../resources/download-svgrepo-com.svg';

import { DOC_URL } from '../serverConfig';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RestContext, ModalContext } from '../App';
import DocumentPreview from '../components/DocumentPreview';

function DocumentView({site, user}) {
    const [docList, setDocList] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState();
    const { openModal, setOpenModal } = useContext(ModalContext);
    
    const { sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        loadDocList();
    }, [site]);

    const loadDocList = () => {
        if (site !== undefined) {
            sendPostRequest(DOC_URL + '/list', { siteId: site.idNo }, (response) => {
                setDocList(response.data);
            });
        }
    }

    const getDocDate = (doc) => {
        const dateArray = doc.docDate.split('T')[0].split('-');
        return `${parseInt(dateArray[1], 10)}/${parseInt(dateArray[2], 10)}/${dateArray[0]}`;
    }

    const mapDocsToRow = () => {
        if (docList.length === 0) {
            return <EmptyRow />
        }

        return docList.map((doc, index) =>
            <tr key={'document-table-row-' + index} onClick={(event) => previewDocument(event, doc)}>
                <td>{doc.docName}</td>
                <td>{doc.docTypeDesc}</td>
                <td>{getDocDate(doc)}</td>
                <td>
                    <a href={getDownloadLink(doc)} onClick={(e) => e.stopPropagation()}><Button className='download-button'><img src={downloadIcon} className='inline-svg' alt="download" /></Button></a>
                </td>
            </tr>
        )
    }

    const getDownloadLink = (doc) => {
        return DOC_URL + '/download/' + doc.docId;
    }

    const togglePreviewModal = () => {
        setOpenModal(openModal === 'document-preview' ? null : 'document-preview');
    }

    const previewDocument = (event, doc) => {
        event.preventDefault();
        setSelectedDoc(doc);
        togglePreviewModal();
    }

    return (
        <div id='document-view-wrapper'>
            <div id='document-view'>
                <table id='document-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th colSpan={2}>Last Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapDocsToRow()}
                    </tbody>
                </table>
                <DocumentPreview isOpen={openModal === 'document-preview'} toggle={togglePreviewModal} doc={selectedDoc} />
            </div>
        </div>
    )
}

function EmptyRow() {
    return (
        <tr id='documents-empty-row'>
            <td colSpan={3}>
                No documents found for this site.
            </td>
        </tr>
    )
}

export default DocumentView;