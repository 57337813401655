import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Label, Spinner } from "reactstrap";

import { RestContext } from "../../../App";
import { CHECKLIST_URL, SITE_URL } from "../../../serverConfig";
import SearchableSelect from "../../../components/SearchableSelect";

function ChecklistInputRangeAdmin() {
    const [siteList, setSiteList] = useState([]);
    const [selectedSite, setSelectedSite] = useState();
    const [checklistList, setChecklistList] = useState([]);
    const [selectedChecklist, setSelectedChecklist] = useState();
    const [inputs, setInputs] = useState([]);
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        sendGetRequest(SITE_URL + "/list", (response) => {
            const newSiteList = response.data.map((site) => ({
                siteId: site.siteId,
                siteText: `${site.siteAbbr} - ${site.fullName}`,
                siteGroup: site.agencyName,
            }));
            setSiteList(newSiteList);
        });
    }, []);

    useEffect(() => {
        if (selectedSite) {
            sendGetRequest(
                CHECKLIST_URL + `/site/${selectedSite}`,
                (response) => {
                    setChecklistList(response.data.checklists);
                    setSelectedChecklist();
                    setInputs([]);
                }
            );
        }
    }, [selectedSite]);

    useEffect(() => {
        if (selectedChecklist && selectedSite) {
            const formData = new FormData();
            formData.append("checklistId", selectedChecklist);
            formData.append("siteId", selectedSite);
            sendPostRequest(
                CHECKLIST_URL + "/inputs/list",
                formData,
                (response) => {
                    setInputs(
                        response.data.filter(
                            (input) => input.inputType === "number"
                        )
                    );
                }
            );
        }
    }, [selectedChecklist]);

    const mapInputs = () => {
        if (!inputs) return null;
        return inputs.map((input, index) => (
            <InputRangeAdmin
                input={input}
                index={index}
                handleChange={handleChange}
            />
        ));
    };

    const handleChange = (index, input) => {
        let newInputs = [...inputs];
        newInputs[index] = input;
        setInputs(newInputs);
        setChanged(true);
    };

    const handleSubmit = () => {
        setWaiting(true);
        sendPostRequest(
            CHECKLIST_URL + "/inputs/update/" + selectedSite,
            inputs,
            (response) => {
                setWaiting(false);
                setChanged(false);
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <div id="checklist-input-range-admin">
            <div className="checklist-admin-section-select-wrapper">
                <Label for="site-select">Site:</Label>
                <SearchableSelect
                    name="site-select"
                    data={siteList}
                    selected={selectedSite}
                    setSelected={setSelectedSite}
                    valField={"siteId"}
                    textField={"siteText"}
                    groupField={"siteGroup"}
                />
                <Label for="checklist-select">Checklist:</Label>
                <SearchableSelect
                    name="checklist-select"
                    data={checklistList}
                    selected={selectedChecklist}
                    setSelected={(value) => setSelectedChecklist(Number(value))}
                    valField={"checklistId"}
                    textField={"checklistName"}
                    disabled={checklistList.length === 0}
                />
            </div>
            <h3>Inputs</h3>
            <div id="checklist-inputs-container">
                {mapInputs()}
                <ChecklistInputRangePlacholder hidden={inputs.length > 0} />
            </div>
            <Button
                className="submit-button"
                onClick={handleSubmit}
                disabled={waiting || !changed}
            >
                {waiting ? <Spinner size="sm" /> : "Save"}
            </Button>
        </div>
    );
}

function InputRangeAdmin({ input, index, handleChange }) {
    const getMinValue = () => {
        if (!input.minValue) return 0;
        return input.minValue;
    };

    const getMaxValue = () => {
        if (!input.maxValue) return 0;
        return input.maxValue;
    };

    const usingDefault = () => {
        if (
            !input ||
            input.useDefault === undefined ||
            input.useDefault === null
        )
            return false;
        return input.useDefault;
    };

    const onChange = (event) => {
        const newInput = { ...input, useDefault: false };
        newInput[event.target.name] = Number(event.target.value);
        handleChange(index, newInput);
    };

    const handleCheckbox = (event) => {
        const newInput = {
            ...input,
            useDefault: !usingDefault(),
            minValue: usingDefault() ? input.minValue : input.defaultMin,
            maxValue: usingDefault() ? input.maxValue : input.defaultMax,
        };

        handleChange(index, newInput);
    };

    return (
        <div className="checklist-input-range-admin-row">
            <div className="checklist-input-text">{input.inputText}</div>
            <div className="checklist-input-range-wrapper">
                <Label for="useDefault">Default</Label>
                <Input
                    type="checkbox"
                    name="useDefault"
                    checked={usingDefault()}
                    onClick={handleCheckbox}
                    readOnly
                />
                <Label for="minValue">Min</Label>
                <Input
                    type="number"
                    name="minValue"
                    step="0.1"
                    value={getMinValue()}
                    onChange={onChange}
                />
                <Label for="maxValue">Max</Label>
                <Input
                    type="number"
                    name="maxValue"
                    step="0.1"
                    value={getMaxValue()}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

function ChecklistInputRangePlacholder({ hidden }) {
    return (
        <div id="checklist-input-range-placeholder" hidden={hidden}>No number inputs found.</div>
    )
}

export default ChecklistInputRangeAdmin;
