import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
} from "reactstrap";
import { FileUploader } from "react-drag-drop-files";

import { default as utilIcons } from "../../../resources/utilIcons";
import { colors } from "../../../styles/colors";

import { ModalContext, RestContext } from "../../../App";
import { CHECKLIST_URL, INV_URL } from "../../../serverConfig";
import SearchableSelect from "../../../components/SearchableSelect";
import MultiSelect from "../../../components/MultiSelect";
import { imageTypes } from "../../../util/globals";
import SortControls from "../../../components/SortControls";

function ChecklistPresetAdmin(props) {
    const [instrumentList, setInstrumentList] = useState([]);
    const [familyList, setFamilyList] = useState([]);
    const [presetList, setPresetList] = useState([]);
    const [selectedInventory, setSelectedInventory] = useState();
    const [selectedPreset, setSelectedPreset] = useState();
    const [presetOptionsOpen, setPresetOptionsOpen] = useState(false);
    const [presetToDelete, setPresetToDelete] = useState();
    const [details, setDetails] = useState([]);
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const { sendGetRequest, sendPostRequest, sendDeleteRequest } =
        useContext(RestContext);
    const { openModal, setOpenModal } = useContext(ModalContext);

    const togglePresetOptionsOpen = () =>
        setPresetOptionsOpen(!presetOptionsOpen);
    const toggleCreateModal = () => {
        if (openModal === "preset-create-modal") {
            setOpenModal();
        } else {
            setOpenModal("preset-create-modal");
        }
    };
    const toggleRenameModal = () => {
        if (openModal === "preset-rename-modal") {
            setOpenModal();
        } else {
            setOpenModal("preset-rename-modal");
        }
    };
    const toggleCopyModal = () => {
        if (openModal === "preset-copy-modal") {
            setOpenModal();
        } else {
            setOpenModal("preset-copy-modal");
        }
    };
    const toggleDeleteModal = () => {
        if (openModal === "preset-delete-modal") {
            setOpenModal();
        } else {
            setOpenModal("preset-delete-modal");
        }
    };

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/combinedInv", (response) => {
            setInstrumentList(response.data.instruments);
            setFamilyList(response.data.families);
        });
    }, []);

    useEffect(() => {
        if (selectedInventory) {
            loadPresetsForSelectedInventory();
            setSelectedPreset();
        }
    }, [selectedInventory]);

    useEffect(() => {
        if (presetList.length > 0) {
            if (!selectedPreset) {
                setSelectedPreset(presetList[0].presetId);
            } else if (
                selectedPreset &&
                !presetList.some((preset) => preset.presetId === selectedPreset)
            ) {
                console.log(2);
                setSelectedPreset(presetList[0].presetId);
            }
        }
    }, [presetList]);

    useEffect(() => {
        if (!selectedPreset) {
            setDetails([]);
        } else if (presetList.length > 0) {
            const preset = presetList.find(
                (preset) => preset.presetId === selectedPreset
            );
            if (preset) {
                setDetails(preset.details);
            }
        }
    }, [selectedPreset]);

    const loadPresetsForSelectedInventory = () => {
        if (selectedInventory < 0) {
            sendGetRequest(
                CHECKLIST_URL + "/presets/family/" + selectedInventory * -1,
                (response) => {
                    setPresetList(response.data.presets);
                }
            );
        } else {
            sendGetRequest(
                CHECKLIST_URL + "/presets/instrument/" + selectedInventory,
                (response) => {
                    setPresetList(response.data.presets);
                }
            );
        }
    };

    const getCurrentPreset = () => {
        if (!selectedPreset || !selectedInventory || !presetList) return null;
        return presetList.find((preset) => preset.presetId === selectedPreset);
    };

    const getCombinedInventoryList = () => {
        if (!familyList || !instrumentList) return null;
        let combinedList = [
            ...familyList.map((family) => ({
                combinedListId: family.familyId * -1,
                combinedListText: family.familyName,
                combinedListGroup: family.instrumentType,
            })),
            ...instrumentList.map((instrument) => ({
                combinedListId: instrument.instrumentId,
                combinedListText: instrument.instrumentName,
                combinedListGroup: instrument.instrumentType,
            })),
        ];

        return combinedList;
    };

    const getCombinedInventoryFilteredByType = () => {
        if (!selectedInventory) return null;
        let combinedList = getCombinedInventoryList();
        const inv = combinedList.find(
            (item) => item.combinedListId === selectedInventory
        );
        if (!inv) return null;
        return combinedList.filter(
            (item) => item.combinedListGroup === inv.combinedListGroup
        );
    };

    const combinedListSort = (a, b) => {
        if (a === "Instrument Family") {
            return -1;
        } else if (b === "Instrument Family") {
            return 1;
        } else {
            return a.localeCompare(b);
        }
    };

    const handleDeletePresetClick = () => {
        const preset = presetList.find(
            (preset) => preset.presetId === selectedPreset
        );
        setPresetToDelete(preset);
        toggleDeleteModal();
    };

    const updateDetails = (event, index) => {
        const newDetail = { ...details[index] };
        newDetail.detailText = event.target.value;
        const newDetails = [...details];
        newDetails.splice(index, 1, newDetail);
        setDetails(newDetails);
        setChanged(true);
    };

    const moveDetail = (oldIndex, newIndex) => {
        const newDetails = [...details];
        const detail = newDetails.splice(oldIndex, 1)[0];
        newDetails.splice(newIndex, 0, detail);
        setDetails(newDetails);
        setChanged(true);
    };

    const deleteDetail = (index) => {
        const newDetails = [...details];
        newDetails.splice(index, 1);
        setDetails(newDetails);
        setChanged(true);
    };

    const mapDetails = () => {
        if (!selectedInventory || !details || details.length === 0) {
            return (
                <div id="checklist-preset-admin-details-placeholder">
                    No details found.
                </div>
            );
        }

        return details.map((detail, index) => (
            <PresetDetail
                key={"preset-detail-" + index}
                index={index}
                detail={detail}
                updateDetails={(event) => updateDetails(event, index)}
                moveFn={moveDetail}
                deleteDetail={deleteDetail}
            />
        ));
    };

    const createDetail = (detail) => {
        setDetails([...details, detail]);
        setChanged(true);
    };

    const createPreset = (presetName) => {
        const payload = {
            presetName: presetName,
            details: [],
        };

        if (selectedInventory < 0) {
            payload.familyId = selectedInventory * -1;
        } else {
            payload.instrumentId = selectedInventory;
        }

        sendPostRequest(CHECKLIST_URL + "/presets", payload, (response) => {
            loadPresetsForSelectedInventory();
            setOpenModal();
        });
    };

    const renamePreset = (presetId, presetName) => {
        const preset = presetList.find(
            (preset) => preset.presetId === presetId
        );
        const payload = {
            presetId: presetId,
            presetName: presetName,
            details: preset.details,
        };

        sendPostRequest(CHECKLIST_URL + "/presets", payload, (response) => {
            loadPresetsForSelectedInventory();
            setOpenModal();
        });
    };

    const copyPreset = (presetId, presetName, newInv) => {
        let payload = {
            presetId: presetId,
            presetName: presetName,
            instrumentId: null,
            familyId: null,
        };

        if (newInv < 0) {
            payload.familyId = newInv * -1;
        } else {
            payload.instrumentId = newInv;
        }

        sendPostRequest(
            CHECKLIST_URL + "/presets/copy",
            payload,
            (response) => {
                loadPresetsForSelectedInventory();
                setOpenModal();
            }
        );
    };

    const deletePreset = (presetId) => {
        sendDeleteRequest(
            CHECKLIST_URL + "/presets/" + presetId,
            (response) => {
                loadPresetsForSelectedInventory();
                setOpenModal();
            }
        );
    };

    const handleSubmit = async () => {
        setWaiting(true);

        const processedDetails = [...details];
        let processingCount = details.length;

        for (let index in processedDetails) {
            const detail = processedDetails[index];
            if (detail.file) {
                const formData = new FormData();
                formData.append("file", detail.file);
                await sendPostRequest(
                    CHECKLIST_URL + "/graphic",
                    formData,
                    (response) => {
                        console.log(response.data);
                        processedDetails[index] = { graphicId: response.data };
                        processingCount--;
                    },
                    (error) => {
                        console.error(error);
                        processingCount--;
                    }
                );
            }
        }

        const preset = presetList.find(
            (preset) => preset.presetId === selectedPreset
        );
        const payload = {
            presetId: selectedPreset,
            presetName: preset.presetName,
            details: processedDetails,
        };

        if (selectedInventory < 0) {
            payload.familyId = selectedInventory * -1;
        } else {
            payload.instrumentId = selectedInventory;
        }

        console.log(payload);

        sendPostRequest(
            CHECKLIST_URL + "/presets",
            payload,
            (response) => {
                setWaiting(false);
                setChanged(false);
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <div id="checklist-preset-admin">
            <div className="checklist-admin-section-select-wrapper">
                <Label for="preset-select">Instrument/Family:</Label>
                <SearchableSelect
                    name="inventory-select"
                    data={getCombinedInventoryList()}
                    selected={selectedInventory}
                    setSelected={(inventoryId) =>
                        setSelectedInventory(Number(inventoryId))
                    }
                    valField={"combinedListId"}
                    textField={"combinedListText"}
                    groupField={"combinedListGroup"}
                    groupSortFn={combinedListSort}
                />
                <Label for="preset-select">Preset:</Label>
                <SearchableSelect
                    name="preset-select"
                    data={presetList}
                    selected={selectedPreset}
                    setSelected={(presetId) =>
                        setSelectedPreset(Number(presetId))
                    }
                    valField={"presetId"}
                    textField={"presetName"}
                    disabled={
                        !(
                            selectedInventory &&
                            presetList &&
                            presetList.length > 0
                        )
                    }
                />
                <Button
                    className="checklist-admin-new-button"
                    onClick={toggleCreateModal}
                >
                    New
                </Button>
                <Dropdown
                    isOpen={presetOptionsOpen}
                    toggle={togglePresetOptionsOpen}
                >
                    <DropdownToggle
                        className="checklist-admin-options-toggle"
                        disabled={!selectedPreset}
                    >
                        {utilIcons.vDots(colors["ars-neutral-400"])}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={toggleRenameModal}>
                            Rename Preset
                        </DropdownItem>
                        <DropdownItem onClick={toggleCopyModal}>
                            Copy Preset
                        </DropdownItem>
                        <DropdownItem
                            style={{ color: colors["ars-error"] }}
                            onClick={handleDeletePresetClick}
                        >
                            Delete Preset
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div id="checklist-preset-admin-details-wrapper">
                <div id="checklist-preset-admin-details-container">
                    {mapDetails()}
                    <DetailCreateRow
                        createDetail={createDetail}
                        hidden={!selectedPreset}
                    />
                </div>
            </div>
            <Button
                className="submit-button"
                onClick={handleSubmit}
                disabled={waiting || !changed}
                hidden={!selectedPreset}
            >
                {waiting ? <Spinner size="sm" /> : "Save"}
            </Button>
            <PresetCreateModal
                isOpen={openModal === "preset-create-modal"}
                toggle={toggleCreateModal}
                createPreset={createPreset}
            />
            <PresetRenameModal
                isOpen={openModal === "preset-rename-modal"}
                toggle={toggleRenameModal}
                preset={getCurrentPreset()}
                renamePreset={renamePreset}
            />
            <PresetCopyModal
                isOpen={openModal === "preset-copy-modal"}
                toggle={toggleCopyModal}
                preset={getCurrentPreset()}
                fromInv={selectedInventory}
                filteredInv={getCombinedInventoryFilteredByType()}
                copyPreset={copyPreset}
            />
            <PresetDeleteModal
                isOpen={openModal === "preset-delete-modal"}
                toggle={toggleDeleteModal}
                preset={presetToDelete}
                deletePreset={deletePreset}
            />
        </div>
    );
}

function PresetDetail({ detail, index, updateDetails, moveFn, deleteDetail }) {
    const getContent = () => {
        if (detail.image) {
            return (
                <img
                    className="checklist-detail-graphic"
                    src={detail.image}
                    alt="Uploaded Image"
                />
            );
        } else if (detail.graphicId) {
            return (
                <img
                    className="checklist-detail-graphic"
                    src={CHECKLIST_URL + "/graphic/" + detail.graphicId}
                    alt="Checklist Graphic"
                />
            );
        } else {
            return (
                <div className="textarea-wrapper">
                    <textarea
                        value={detail.detailText}
                        onChange={updateDetails}
                    />
                </div>
            );
        }
    };

    const handleDelete = () => {
        deleteDetail(index);
    };

    return (
        <div className="checklist-preset-admin-detail">
            <SortControls index={index} updateFn={moveFn} />
            <div className="checklist-preset-admin-detail-body">
                {getContent()}
            </div>
            <Button
                className="checklist-editor-delete-button"
                onClick={handleDelete}
            >
                -
            </Button>
        </div>
    );
}

function PresetCreateModal({ isOpen, toggle, createPreset }) {
    const [nameString, setNameString] = useState("");

    const handleChange = (event) => {
        setNameString(event.target.value);
    };

    const handleSubmit = (event) => {
        createPreset(nameString);
    };

    return (
        <Modal
            className="checklist-create-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Enter Preset Name</ModalHeader>
            <ModalBody>
                <Input
                    value={nameString}
                    onChange={handleChange}
                    placeholder="Preset name..."
                />
                <Button className="submit-button" onClick={handleSubmit}>
                    Create
                </Button>
            </ModalBody>
        </Modal>
    );
}

function PresetRenameModal({ isOpen, toggle, preset, renamePreset }) {
    const [nameString, setNameString] = useState("");

    const getPresetName = () => {
        if (!preset) return "";
        return preset.presetName;
    };

    const handleChange = (event) => {
        setNameString(event.target.value);
    };

    const handleSubmit = (event) => {
        renamePreset(preset.presetId, nameString);
    };

    return (
        <Modal
            className="checklist-rename-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Change Preset Name</ModalHeader>
            <ModalBody>
                <div>
                    <Label>Old Name:</Label>
                    <div>{getPresetName()}</div>
                </div>
                <div>
                    <Label>New Name:</Label>
                    <Input
                        value={nameString}
                        onChange={handleChange}
                        placeholder="Preset name..."
                        invalid={nameString.length === 0}
                    />
                </div>
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={nameString.length === 0}
                >
                    Save
                </Button>
            </ModalBody>
        </Modal>
    );
}

function PresetDeleteModal({ isOpen, toggle, preset, deletePreset }) {
    const getPresetName = () => {
        if (!preset) return "";
        return preset.presetName;
    };

    const handleClick = () => {
        if (!preset) return;
        deletePreset(preset.presetId);
    };

    return (
        <Modal
            className="checklist-delete-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody>
                <div>
                    {"Are you sure you want to permanently delete "}
                    <b>{getPresetName()}</b>
                    {"?"}
                </div>
                <div className="checklist-delete-modal-buttons">
                    <Button
                        className="checklist-delete-modal-delete"
                        onClick={handleClick}
                    >
                        Delete
                    </Button>
                    <Button
                        className="checklist-delete-modal-cancel"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

function PresetCopyModal({
    isOpen,
    toggle,
    fromInv,
    preset,
    copyPreset,
    filteredInv,
}) {
    const [presetName, setPresetName] = useState();
    const [toInv, setToInv] = useState();

    useEffect(() => {
        init();
    }, [preset, fromInv]);

    const init = () => {
        if (preset && fromInv) {
            setPresetName(preset.presetName);
            setToInv(fromInv);
        }
    };

    const handleChange = (event) => {
        setPresetName(event.target.value);
    };

    const handleSubmit = () => {
        if (!presetName || !toInv || !preset || !preset.presetId) return;
        copyPreset(preset.presetId, presetName, toInv);
    };

    return (
        <Modal
            className="checklist-copy-modal"
            isOpen={isOpen}
            toggle={toggle}
            onOpened={init}
        >
            <ModalHeader toggle={toggle}>Copy Preset</ModalHeader>
            <ModalBody>
                <div>
                    <Label>Inventory:</Label> 
                    <SearchableSelect
                        name="inventory-select"
                        data={filteredInv}
                        selected={toInv}
                        setSelected={(inventoryId) =>
                            setToInv(Number(inventoryId))
                        }
                        valField={"combinedListId"}
                        textField={"combinedListText"}
                    />
                </div>
                <div>
                    <Label>Preset Name:</Label>
                    <Input value={presetName} onChange={handleChange} />
                </div>
                <Button className="submit-button" onClick={handleSubmit}>Submit</Button>
            </ModalBody>
        </Modal>
    );
}

function DetailCreateRow({ createDetail, hidden }) {
    const { openModal, setOpenModal } = useContext(ModalContext);
    const toggleUploadModal = () => {
        if (openModal === "preset-graphic-upload") {
            setOpenModal();
        } else {
            setOpenModal("preset-graphic-upload");
        }
    };

    const handleAddText = () => {
        createDetail({ detailText: "" });
    };

    const handleAddGraphic = () => {
        setOpenModal("preset-graphic-upload");
    };

    return (
        <div id="checklist-preset-admin-detail-create-wrapper" hidden={hidden}>
            <Button onClick={handleAddText}>Add text +</Button>
            <Button onClick={handleAddGraphic}>Add graphic +</Button>
            <GraphicUploadModal
                isOpen={openModal === "preset-graphic-upload"}
                toggle={toggleUploadModal}
                createDetail={createDetail}
            />
        </div>
    );
}

function GraphicUploadModal({ isOpen, toggle, createDetail }) {
    const [file, setFile] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);

    useEffect(() => {
        if (file) {
            console.log(file);
            setPreviewFile(URL.createObjectURL(file));
        } else {
            setPreviewFile(null);
        }
    }, [file]);

    const handleClose = () => {
        setFile(null);
    };

    const handleChange = (file) => {
        setFile(file);
    };

    const handleSubmit = () => {
        if (!file) return;
        createDetail({ file: file, image: previewFile });
        toggle();
    };

    return (
        <Modal
            id="checklist-preset-admin-graphic-upload-modal"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={handleClose}
        >
            <ModalHeader toggle={toggle}>Upload graphic</ModalHeader>
            <ModalBody>
                <img src={previewFile} />
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={imageTypes}
                />
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={file === null}
                >
                    Upload
                </Button>
            </ModalBody>
        </Modal>
    );
}

export default ChecklistPresetAdmin;
