import React, { useState } from 'react';
import { Input, Form, Button, Spinner } from 'reactstrap';

import '../styles/login.css';
import PasswordField from '../components/PasswordField';

function Login({ invalid, setInvalid, setWaiting, handleLogin, waiting, toggleSignup, toggleForgotModal }) {
    const [formData, setFormData] = useState({ username: '', password: '' });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }));
        setInvalid(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setWaiting(true);
        handleLogin(formData);
        setFormData(values => ({ ...values, password: '' }));
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter') handleSubmit(event);
    }

    const invalidLoginMessage = () => {
        if (invalid) return <div id='invalid-login' hidden={!invalid}>Invalid Credentials. Please check your username and password and try again.</div>
        else return null;
    }

    return (
        <div id='auth-page'>
            <div id='login'>
                <Form onSubmit={handleSubmit}>
                    <div id='login-header'>Login</div>
                    <div id='login-body'>
                        <Input name='username' placeholder='Username' onChange={handleChange} onKeyDown={submitOnEnter} value={formData.username} invalid={invalid} />
                        <PasswordField fieldName='password' title={"Password"} handleChange={handleChange} onKeyDown={submitOnEnter} value={formData['password']} invalid={invalid} />
                        <Button onClick={handleSubmit} className='submit-button'>{waiting ? <Spinner size={'sm'} /> : 'Login'}</Button>
                        {invalidLoginMessage()}
                        <div id='signup-prompt'>
                            <span className='inline-link' onClick={toggleSignup}>Request Account</span>
                        </div>
                        <div id='forgot-password-prompt'>
                            <span className='inline-link' onClick={toggleForgotModal}>Forgot Password?</span>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Login;