import React, { useContext, useEffect, useState } from 'react';

import '../styles/SiteInventory.css';

import { INV_URL } from '../serverConfig';
import { getInventoryIcon } from "../util/instrumentTypes";
import { RestContext } from '../App';

function SiteInventory({ site }) {
    const [invList, setInvList] = useState([]);

    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        if (site !== undefined) {
            sendGetRequest(INV_URL + '/list/' + site.idNo, (response) => {
                setInvList(response.data);
            });
        }
    }, [site]);

    const mapInvToInventoryRow = () => {
        return invList.map((inventory, index) => {

            const disallowedTypes = ['Infrastructure', 'Tower'];
            if (disallowedTypes.includes(inventory.instrumentType)) return null;
            
            return <InventoryRow key={'inventory-list-row-' + index} inventory={inventory} />
        })
    }

    return (
        <div id='site-inventory'>
            <div id='site-inventory-rows'>
                <table>
                    <tbody>
                        <NoInventory hidden={invList.length > 0} />
                        {mapInvToInventoryRow()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function InventoryRow({ inventory }) {

    const getName = () => {
        if (inventory === undefined) return null;
        return inventory.instrumentFullName;
    }

    const getType = () => {
        if (inventory === undefined) return null;
        return inventory.instrumentType;
    }

    const getTypeSymbol = () => {
        const type = getType();
        return <img src={getInventoryIcon(type)} className="instrument-type-symbol" alt={type} />
    }

    const getSerialNumber = () => {
        if (inventory === undefined) return null;
        return inventory.serialNumber;
    }

    const getDateString = () => {
        if (inventory === undefined) return null;
        const date = inventory.installDate.split('T')[0].split('-');
        return `${date[1]}/${date[2]}/${date[0]}`
    }

    const olderThanTen = () => {
        const tenYearsAgo = new Date();
        tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
        if (new Date(inventory.installDate) < tenYearsAgo) return true;
        return false;
    }

    return (
        <tr className="inventory-row">
            <td className="inventory-row-text">
                <div className="inventory-row-name">{getName()}</div>
                <div className="inventory-row-serial">SN: {getSerialNumber()}</div>
                <div className="inventory-row-date" hidden={olderThanTen()}>Installed {getDateString()}</div>
            </td>
            <td className="inventory-row-type">
                {getTypeSymbol()}
                <div>{getType()}</div>
            </td>
        </tr>
    )
}

function NoInventory({ hidden }) {
    return (
        <tr id='inventory-row-placeholder' hidden={hidden}>
            <td colSpan={2}>No inventory found.</td>
        </tr>
    )
}

export default SiteInventory;