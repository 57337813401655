import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Button,
    Collapse,
    Input,
    Label,
    Modal,
    ModalBody,
    Popover,
    PopoverBody,
    Spinner,
    UncontrolledPopover,
} from "reactstrap";

import { default as utilIcons } from "../../resources/utilIcons";
import { colors } from "../../styles/colors";

import BackButton from "../../components/BackButton";
import { CHECKLIST_URL } from "../../serverConfig";
import { ModalContext, RestContext } from "../../App";

const ChecklistContext = createContext(null);

function Checklist({
    checklist,
    setActiveChecklist,
    handleSubmit,
    instanceId,
    onComplete,
}) {
    const [instructions, setInstructions] = useState([]);
    const [checklistData, setChecklistData] = useState({});
    const [openInstruction, setOpenInstruction] = useState(0);
    const [conditionFlags, setConditionFlags] = useState({});
    const [visibleInstructions, setVisibleInstructions] = useState([]);
    const [completedInstructions, setCompletedInstructions] = useState([]);

    const { sendPostRequest } = useContext(RestContext);
    const { openModal, setOpenModal } = useContext(ModalContext);

    const toggleCancelModal = () => {
        if (openModal === "checklist-cancel-modal") {
            setOpenModal();
        } else {
            setOpenModal("checklist-cancel-modal");
        }
    };

    useEffect(() => {
        if (checklist) {
            setInstructions(checklist.instructions);
            setCompletedInstructions(
                Array.from(
                    { length: checklist.instructions.length },
                    (_, i) => false
                )
            );
            if (checklist.instanceData) {
                setChecklistData(parseData(checklist.instanceData));
            }
        }
    }, [checklist]);

    useEffect(() => {
        console.log(checklistData);
    }, [checklistData]);

    useEffect(() => {
        if (instructions && instructions.length) {
            const firstInstruction = document.querySelector(
                "#checklist .checklist-instruction:first-child"
            );

            if (firstInstruction) firstInstruction.scrollIntoView();
        }
    }, [instructions]);

    useEffect(() => {
        if (conditionFlags && instructions) {
            let newVisibleInstructions = Array.from(
                { length: checklist.instructions.length },
                (_, i) => true
            );
            let newChecklistData = { ...checklistData };
            for (let i in instructions) {
                if (
                    instructions[i].conditions &&
                    instructions[i].conditions.length > 0
                ) {
                    if (
                        !instructions[i].conditions.some(
                            (condition) => conditionFlags[condition]
                        )
                    ) {
                        newVisibleInstructions[i] = false;
                        for (let input of instructions[i].inputs) {
                            delete newChecklistData[input.inputId];
                        }
                    }
                }
            }
            setVisibleInstructions(newVisibleInstructions);
        }
    }, [conditionFlags, instructions]);

    const toggleOpenInstruction = (index) => {
        if (openInstruction === index) {
            setOpenInstruction();
        } else {
            setOpenInstruction(index);
        }
    };

    const parseData = (data) => {
        let parsedData = {};
        let newFlags = {};
        for (let inputId in data) {
            let value;

            if (data[inputId].value === "yes") {
                newFlags[data[inputId].input.condition] = true;
            } else if (data[inputId].value === "no") {
                newFlags[data[inputId].input.condition] = false;
            }

            if (!isNaN(Number(data[inputId].value))) {
                value = Number(data[inputId].value);
            } else {
                value = data[inputId].value;
            }
            parsedData[inputId] = {
                input: data[inputId].input,
                value: value,
            };
        }
        setConditionFlags(newFlags);
        return parsedData;
    };

    const updateData = (inputId, inputObj) => {
        const newData = { ...checklistData, [inputId]: inputObj };
        setChecklistData(newData);
        if (instanceId) {
            const formData = new FormData();
            formData.append("instanceId", instanceId);
            formData.append("inputId", inputId);
            formData.append("value", inputObj.value);
            sendPostRequest(CHECKLIST_URL + "/instance/data", formData);
        }
    };

    const mapInstructions = () => {
        if (!instructions) return null;
        return instructions.map((instruction, index) => (
            <ChecklistInstruction
                instruction={instruction}
                index={index}
                isOpen={openInstruction === index}
                toggle={toggleOpenInstruction}
            />
        ));
    };

    const handleBackButton = (event) => {
        setActiveChecklist();
    };

    const canSubmit = () => {
        return !Object.keys(checklistData).some(
            (inputId) =>
                checklistData[inputId] === null ||
                checklistData[inputId] === "" ||
                checklistData[inputId] === undefined
        );
    };

    const cancelChecklist = () => {
        if (!instanceId) return;
        sendPostRequest(
            CHECKLIST_URL + "/instance/cancel/" + instanceId,
            null,
            (response) => {
                setOpenModal();
                onComplete();
            }
        );
    };

    return (
        <div id="checklist">
            <ChecklistContext.Provider
                value={{
                    conditionFlags,
                    setConditionFlags,
                    visibleInstructions,
                    setVisibleInstructions,
                    checklistData,
                    updateData,
                    instanceId,
                    onComplete,
                }}
            >
                <div id="checklist-header">
                    <BackButton onClick={handleBackButton} />
                    <div id="checklist-header-name">
                        {checklist.checklistName}
                    </div>
                    <ChecklistStationLogPopup instanceId={instanceId} />
                    <Button
                        id="checklist-header-cancel-button"
                        hidden={!instanceId}
                        onClick={toggleCancelModal}
                    >
                        Cancel
                    </Button>
                </div>
                <div
                    id="checklist-instructions-container"
                    onScroll={(event) => event.preventDefault()}
                >
                    {mapInstructions()}
                    <ChecklistReport canSubmit={canSubmit} />
                </div>
                <ChecklistCancelModal
                    isOpen={openModal === "checklist-cancel-modal"}
                    toggle={toggleCancelModal}
                    cancelChecklist={cancelChecklist}
                />
            </ChecklistContext.Provider>
        </div>
    );
}

function ChecklistInstruction({ instruction, index }) {
    const ref = useRef(null);
    const { visibleInstructions, checklistData } = useContext(ChecklistContext);

    const mapDetails = () => {
        if (!instruction || !instruction.details) return null;
        return instruction.details.map((detail, index) => {
            if (detail.graphicId) {
                return (
                    <div className="checklist-instruction-detail-image-wrapper">
                        <img
                            className="checklist-instruction-detail-image"
                            src={CHECKLIST_URL + "/graphic/" + detail.graphicId}
                        />
                    </div>
                );
            } else {
                return (
                    <p className="checklist-instruction-detail-text">
                        {detail.detailText}
                    </p>
                );
            }
        });
    };

    const mapInputs = () => {
        if (!instruction || !instruction.inputs) return null;
        return instruction.inputs.map((input, index) => {
            switch (input.inputType) {
                case "yesno":
                    return <ChecklistYesNoInput input={input} />;
                case "number":
                    return <ChecklistNumberInput input={input} />;
                default:
                    return null;
            }
        });
    };

    const isHidden = () => {
        if (!visibleInstructions || visibleInstructions.length <= index)
            return true;
        return !visibleInstructions[index];
    };

    const getIndex = () => {
        if (!visibleInstructions || visibleInstructions.length <= index)
            return -1;
        let displayIndex = 1;
        for (let i = 0; i < index; i++) {
            if (visibleInstructions[i]) {
                displayIndex++;
            }
        }
        return displayIndex;
    };

    const nextDisabled = () => {
        if (!instruction.inputs || !checklistData) return false;
        const isDisabled = instruction.inputs.some(
            (input) => !(input.inputId in checklistData)
        );
        return isDisabled;
    };

    const scrollToNext = () => {
        if (ref.current) {
            const next = ref.current.nextSibling;
            next.scrollIntoView({ behavior: "smooth" });
        }
    };

    const scrollToPrev = () => {
        if (ref.current) {
            const prev = ref.current.previousSibling;
            prev.scrollIntoView({ behavior: "smooth" });
        }
    };

    return isHidden() ? null : (
        <div className="checklist-instruction" ref={ref}>
            <div className="checklist-instruction-header">
                <div className="checklist-instruction-index">
                    {getIndex() + ")"}
                </div>
                <div className="checklist-instruction-text">
                    {instruction.instructionText}
                </div>
            </div>
            <div className="checklist-instruction-body">
                <div className="checklist-instruction-inputs-container">
                    {mapInputs()}
                </div>
                <div className="checklist-instruction-details-container">
                    {mapDetails()}
                </div>
            </div>
            <div className="checklist-instruction-buttons">
                <Button
                    className="checklist-instruction-prev-button"
                    hidden={index === 0}
                    onClick={scrollToPrev}
                >
                    {utilIcons.caret(colors["ars-neutral-900"])}
                </Button>
                <Button
                    className="checklist-instruction-next-button"
                    disabled={nextDisabled()}
                    onClick={scrollToNext}
                >
                    <span>Next</span>
                    {utilIcons.caret(colors["ars-neutral-900"])}
                </Button>
            </div>
        </div>
    );
}

function ChecklistYesNoInput({ input }) {
    const { setConditionFlags, checklistData, updateData } =
        useContext(ChecklistContext);
    const flagCondition = (event) => {
        updateData(input.inputId, { input: input, value: "yes" });
        if (input.condition) {
            setConditionFlags((values) => ({
                ...values,
                [input.condition]: true,
            }));
        }
    };

    const unflagCondition = (event) => {
        updateData(input.inputId, { input: input, value: "no" });
        if (input.condition) {
            setConditionFlags((values) => ({
                ...values,
                [input.condition]: false,
            }));
        }
    };

    const getValue = () => {
        if (!checklistData || !checklistData[input.inputId]) return false;
        return checklistData[input.inputId].value;
    };

    return (
        <div className="checklist-instruction-input-yesno">
            <Label>{input.inputText}</Label>
            <div className="checklist-instruction-input-yesno-wrapper">
                <span>Yes</span>
                <Input
                    type="radio"
                    name={"input-" + input.inputId}
                    value={"yes"}
                    checked={getValue() === "yes"}
                    onClick={flagCondition}
                    readOnly
                />
                <span>No</span>
                <Input
                    type="radio"
                    name={"input-" + input.inputId}
                    value={"no"}
                    checked={getValue() === "no"}
                    onClick={unflagCondition}
                    readOnly
                />
            </div>
        </div>
    );
}

function ChecklistNumberInput({ input }) {
    const [outOfRange, setOutOfRange] = useState(false);
    const [focus, setFocus] = useState(false);
    const { checklistData, updateData } = useContext(ChecklistContext);

    const ref = useRef(null);

    useEffect(() => {
        if (input) {
            if (!valInRange()) {
                setOutOfRange(true);
            } else {
                setOutOfRange(false);
            }
        }
    }, [input]);

    const getInputPaddingRight = () => {
        if (input.inputUnit) {
            if (ref.current) {
                const unitElement = ref.current.querySelector(".checklist-instruction-input-number-unit");
                console.log(unitElement.offsetWidth);
                return "calc(8px + " + (unitElement.offsetWidth + "px") + ")";
            } else {
                return "calc(8px + " + (input.inputUnit.length + "ch") + ")";
            }
        }
    };

    const getVal = () => {
        if (!checklistData || !checklistData[input.inputId]) return null;
        return checklistData[input.inputId].value;
    };

    const getRangeString = () => {
        if (!input) return "";
        if (input.minValue !== null && input.maxValue !== null) {
            return (
                input.minValue +
                " — " +
                input.maxValue +
                (input.inputUnit ? " " + input.inputUnit : "")
            );
        } else if (input.minValue !== null) {
            return (
                ">" +
                input.minValue +
                (input.inputUnit ? " " + input.inputUnit : "")
            );
        } else if (input.maxValue !== null) {
            return (
                "<" +
                input.maxValue +
                (input.inputUnit ? " " + input.inputUnit : "")
            );
        }
    };

    const handleChange = (event) => {
        const value = Number(event.target.value);
        if (valInRange(value)) {
            setOutOfRange(false);
        } else {
            setOutOfRange(true);
        }
        updateData(input.inputId, {
            input: input,
            value: value,
        });
    };

    const valInRange = (val = getVal()) => {
        if (val === null) return true;
        if (
            !(input.maxValue === null || input.maxValue === undefined) &&
            val > input.maxValue
        )
            return false;
        if (
            !(input.minValue === null || input.minValue === undefined) &&
            val < input.minValue
        )
            return false;
        return true;
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        setFocus(false);
    };

    return (
        <div
            className={
                "checklist-instruction-input-number" +
                (valInRange() ? "" : " out-of-range")
            }
            ref={ref}
        >
            <Label>{input.inputText}</Label>
            <div className="checklist-instruction-input-range">
                {getRangeString()}
            </div>
            <div className="checklist-instruction-input-number-wrapper">
                <Input
                    id={"checklist-input-" + input.inputId}
                    type="number"
                    step={0.1}
                    name={"input-" + input.inputId}
                    style={{ paddingRight: getInputPaddingRight() }}
                    value={getVal()}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <span className="checklist-instruction-input-number-unit">
                    {input.inputUnit}
                </span>
                <Popover
                    isOpen={outOfRange && focus}
                    target={"checklist-input-" + input.inputId}
                    hidden={getVal() === null}
                >
                    <PopoverBody>Value outside of expected range. Contact ARS: <a href="tel:970-484-7941">(970) 484-7941</a></PopoverBody>
                </Popover>
            </div>
        </div>
    );
}

function ChecklistReport({ canSubmit }) {
    const [waiting, setWaiting] = useState(false);
    const ref = useRef(null);
    const { checklistData, instanceId, onComplete } =
        useContext(ChecklistContext);
    const { sendPostRequest } = useContext(RestContext);

    const scrollToPrev = () => {
        if (ref.current) {
            const prev = ref.current.previousSibling;
            prev.scrollIntoView({ behavior: "smooth" });
        }
    };

    const formatDataValue = (value) => {
        if (value === true) {
            return "Yes";
        } else if (value === false) {
            return "No";
        } else {
            return value;
        }
    };

    const mapChecklistData = () => {
        if (!checklistData) return null;
        return Object.keys(checklistData).map((inputId, index) => (
            <tr className="checklist-report-row">
                <td className="checklist-report-input-text">
                    {checklistData[inputId].input.inputText}
                </td>
                <td className="checklist-report-input-value">
                    {formatDataValue(checklistData[inputId].value) +
                        (checklistData[inputId].input.inputUnit
                            ? checklistData[inputId].input.inputUnit
                            : "")}
                </td>
            </tr>
        ));
    };

    const submitChecklist = () => {
        if (!instanceId || waiting) return;
        setWaiting(true);
        sendPostRequest(
            CHECKLIST_URL + "/instance/submit/" + instanceId,
            null,
            (response) => {
                setWaiting(false);
                onComplete();
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <div className="checklist-instruction checklist-report" ref={ref}>
            <div className="checklist-instruction-header">
                <div className="checklist-instruction-text">
                    Review Checklist
                </div>
            </div>
            <div className="checklist-instruction-body">
                <table>
                    <tbody>{mapChecklistData()}</tbody>
                </table>
            </div>
            <div className="checklist-instruction-buttons">
                <Button
                    className="checklist-instruction-prev-button"
                    onClick={scrollToPrev}
                >
                    {utilIcons.caret(colors["ars-neutral-900"])}
                </Button>
                <Button
                    className="submit-button"
                    disabled={!canSubmit()}
                    onClick={submitChecklist}
                >
                    Submit Checklist
                </Button>
            </div>
        </div>
    );
}

function ChecklistCancelModal({ isOpen, toggle, cancelChecklist }) {
    const handleClick = () => {
        cancelChecklist();
    };

    return (
        <Modal
            className="checklist-cancel-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody>
                <div>
                    Are you sure you want to cancel this checklist?{" "}
                    <b>All input data will be permanently lost.</b>
                </div>
                <div className="checklist-cancel-modal-buttons">
                    <Button
                        className="checklist-cancel-modal-confirm"
                        onClick={handleClick}
                    >
                        Yes
                    </Button>
                    <Button
                        className="checklist-cancel-modal-abort"
                        onClick={toggle}
                    >
                        No
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

function ChecklistStationLogPopup({ instanceId }) {
    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [waiting, setWaiting] = useState(false);
    const ref = useRef(null);
    const { sendPostRequest } = useContext(RestContext);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && isOpen) {
                if (
                    !ref.current.contains(event.target) &&
                    event.target.id !== "site-select"
                ) {
                    toggle();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isOpen, toggle]);

    const handleChange = (event) => {
        setInputText(event.target.value);
    };

    const canSubmit = () => {
        return instanceId && inputText.length > 0;
    };

    const handleSubmit = () => {
        setWaiting(true);
        const formData = new FormData();
        formData.append("instanceId", instanceId);
        formData.append("detail", inputText);
        sendPostRequest(
            CHECKLIST_URL + "/instance/note",
            formData,
            (response) => {
                setInputText("");
                setIsOpen(false);
                setWaiting(false);
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <div id="checklist-station-log-popup-wrapper" ref={ref}>
            <Button id="checklist-station-log-popup-toggle" onClick={toggle}>
                {utilIcons.pencil(colors["ars-neutral-900"])}
            </Button>
            <div id="checklist-station-log-popup-collapse" hidden={!isOpen}>
                <div id="checklist-station-log-popup">
                    <p>Write a note to the Station Log.</p>
                    <div id="checklist-station-log-popup-input-wrapper">
                        <textarea
                            id="checklist-station-log-popup-input"
                            type="textarea"
                            maxLength={2000}
                            value={inputText}
                            onChange={handleChange}
                        />
                        <Button
                            id="checklist-station-log-popup-submit"
                            disabled={!canSubmit()}
                            onClick={handleSubmit}
                        >
                            {waiting ? (
                                <Spinner size="sm" />
                            ) : (
                                utilIcons.leftArrow(colors["ars-neutral-900"], {
                                    rotate: "180deg",
                                })
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checklist;
