import React, { useContext, useEffect, useState } from "react";

import { default as utilIcons } from "../../../resources/utilIcons";

import { ModalContext, RestContext } from "../../../App";
import { CHECKLIST_URL, INV_URL } from "../../../serverConfig";
import { colors } from "../../../styles/colors";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
} from "reactstrap";
import SearchableSelect from "../../../components/SearchableSelect";
import MultiSelect from "../../../components/MultiSelect";

function ChecklistInstrumentFamilyAdmin(props) {
    const [instrumentFamilies, setInstrumentFamilies] = useState([]);
    const [instrumentModels, setInstrumentModels] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState();
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedType, setSelectedType] = useState();
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [familyOptionsOpen, setFamilyOptionsOpen] = useState(false);
    const [familyToDelete, setFamilyToDelete] = useState(false);

    const { sendGetRequest, sendPostRequest, sendDeleteRequest } =
        useContext(RestContext);
    const { openModal, setOpenModal } = useContext(ModalContext);

    const toggleFamilyOptionsOpen = () =>
        setFamilyOptionsOpen(!familyOptionsOpen);
    const toggleCreateModal = () => {
        if (openModal === "instrument-family-create-modal") {
            setOpenModal();
        } else {
            setOpenModal("instrument-family-create-modal");
        }
    };
    const toggleRenameModal = () => {
        if (openModal === "instrument-family-rename-modal") {
            setOpenModal();
        } else {
            setOpenModal("instrument-family-rename-modal");
        }
    };
    const toggleDeleteModal = () => {
        if (openModal === "instrument-family-delete-modal") {
            setOpenModal();
        } else {
            setOpenModal("instrument-family-delete-modal");
        }
    };

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/instrumentFamilies", (response) => {
            setInstrumentFamilies(response.data.instrumentFamilies);
        });
        sendGetRequest(INV_URL + "/instruments", (response) => {
            setInstrumentModels(response.data);
        });
    }, []);

    useEffect(() => {
        if (instrumentFamilies.length > 0) {
            if (
                selectedFamily &&
                !instrumentFamilies.some(
                    (family) => family.familyId === selectedFamily
                )
            ) {
                setSelectedFamily(instrumentFamilies[0].familyId);
            }
        }
    }, [instrumentFamilies]);

    useEffect(() => {
        if (selectedFamily) {
            const family = instrumentFamilies.find(
                (family) => family.familyId === selectedFamily
            );
            if (family) {
                setSelectedModels(family.instruments);
                if (family.instrumentType) {
                    setSelectedType(family.instrumentType);
                }
            }
            setChanged(false);
        }
    }, [selectedFamily]);

    useEffect(() => {
        if (selectedModels.length > 0) {
            setSelectedType(selectedModels[0].instrumentType);
        }
    }, [selectedModels]);

    const getCurrentFamily = () => {
        if (!selectedFamily) return null;
        else
            return instrumentFamilies.find(
                (family) => family.familyId === selectedFamily
            );
    };

    const handleChange = (event) => {
        setChanged(true);
    };

    const handleSubmit = (event) => {
        setWaiting(true);
        const family = instrumentFamilies.find(
            (family) => family.familyId === selectedFamily
        );
        const payload = {
            familyId: selectedFamily,
            familyName: family.familyName,
            instrumentType: selectedType,
            instruments: selectedModels,
        };

        sendPostRequest(
            CHECKLIST_URL + "/instrumentFamilies",
            payload,
            (response) => {
                sendGetRequest(
                    CHECKLIST_URL + "/instrumentFamilies",
                    (response) => {
                        setInstrumentFamilies(response.data.instrumentFamilies);
                        setChanged(false);
                        setWaiting(false);
                    }
                );
            },
            (response) => {
                setWaiting(false);
            }
        );
    };

    const createFamily = (familyName) => {
        setWaiting(true);
        const payload = {
            familyName: familyName,
        };

        sendPostRequest(
            CHECKLIST_URL + "/instrumentFamilies",
            payload,
            (response) => {
                sendGetRequest(
                    CHECKLIST_URL + "/instrumentFamilies",
                    (response) => {
                        setInstrumentFamilies(response.data.instrumentFamilies);
                        setWaiting(false);
                        setOpenModal();
                    }
                );
            },
            (response) => {
                setWaiting(false);
            }
        );
    };

    const renameFamily = (familyId, familyName) => {
        const family = instrumentFamilies.find(
            (family) => family.familyId === familyId
        );
        if (!family) return;

        setWaiting(true);
        const payload = {
            familyName: familyName,
            familyId: familyId,
            instrumentType: family.instrumentType,
            instruments: family.instruments,
        };
        sendPostRequest(
            CHECKLIST_URL + "/instrumentFamilies",
            payload,
            (response) => {
                sendGetRequest(
                    CHECKLIST_URL + "/instrumentFamilies",
                    (response) => {
                        setInstrumentFamilies(response.data.instrumentFamilies);
                        setWaiting(false);
                        setOpenModal();
                    }
                );
            },
            (response) => {
                setWaiting(false);
            }
        );
    };

    const handleDeleteFamilyClick = () => {
        const family = instrumentFamilies.find(
            (family) => family.familyId === selectedFamily
        );
        setFamilyToDelete(family);
        toggleDeleteModal();
    };

    const deleteFamily = (familyId) => {
        setWaiting(true);
        sendDeleteRequest(
            CHECKLIST_URL + "/instrumentFamilies/" + familyId,
            (response) => {
                sendGetRequest(
                    CHECKLIST_URL + "/instrumentFamilies",
                    (response) => {
                        setInstrumentFamilies(response.data.instrumentFamilies);
                        setWaiting(false);
                        setOpenModal();
                    }
                );
            },
            (response) => {
                setWaiting(false);
            }
        );
    };

    const getFilteredModels = () => {
        if (selectedModels.length === 0 || !selectedType) {
            return instrumentModels;
        } else {
            return instrumentModels.filter(
                (model) => model.instrumentType === selectedType
            );
        }
    };

    return (
        <div id="checklist-instrument-family-admin">
            <div className="checklist-admin-section-select-wrapper">
                <Label for="instrument-family-select">Family:</Label>
                <SearchableSelect
                    name="instrument-family-select"
                    data={instrumentFamilies}
                    selected={selectedFamily}
                    setSelected={(familyId) =>
                        setSelectedFamily(Number(familyId))
                    }
                    valField={"familyId"}
                    textField={"familyName"}
                />
                <Button
                    className="checklist-admin-new-button"
                    onClick={toggleCreateModal}
                >
                    New
                </Button>
                <Dropdown
                    isOpen={familyOptionsOpen}
                    toggle={toggleFamilyOptionsOpen}
                >
                    <DropdownToggle
                        className="checklist-admin-options-toggle"
                        disabled={!selectedFamily}
                    >
                        {utilIcons.vDots(colors["ars-neutral-400"])}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={toggleRenameModal}>
                            Rename Family
                        </DropdownItem>
                        <DropdownItem
                            style={{ color: colors["ars-error"] }}
                            onClick={handleDeleteFamilyClick}
                        >
                            Delete Family
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <MultiSelect
                data={getFilteredModels()}
                selected={selectedModels}
                setSelected={setSelectedModels}
                valField={"instrumentId"}
                textField={"instrumentName"}
                groupField={"instrumentType"}
                onChange={handleChange}
            />
            <Button
                className="submit-button"
                onClick={handleSubmit}
                disabled={waiting || !changed}
            >
                {waiting ? <Spinner size="sm" /> : "Save"}
            </Button>
            <InstrumentFamilyCreateModal
                isOpen={openModal === "instrument-family-create-modal"}
                toggle={toggleCreateModal}
                createFamily={createFamily}
            />
            <InstrumentFamilyRenameModal
                isOpen={openModal === "instrument-family-rename-modal"}
                toggle={toggleRenameModal}
                family={getCurrentFamily()}
                renameFamily={renameFamily}
            />
            <InstrumentFamilyDeleteModal
                isOpen={openModal === "instrument-family-delete-modal"}
                toggle={toggleDeleteModal}
                family={familyToDelete}
                deleteFamily={deleteFamily}
            />
        </div>
    );
}

function InstrumentFamilyCreateModal({ isOpen, toggle, createFamily }) {
    const [nameString, setNameString] = useState("");

    const handleChange = (event) => {
        setNameString(event.target.value);
    };

    const handleSubmit = (event) => {
        createFamily(nameString);
    };

    return (
        <Modal
            className="checklist-create-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Enter Family Name</ModalHeader>
            <ModalBody>
                <Input
                    value={nameString}
                    onChange={handleChange}
                    placeholder="Family name..."
                />
                <Button className="submit-button" onClick={handleSubmit}>
                    Create
                </Button>
            </ModalBody>
        </Modal>
    );
}

function InstrumentFamilyRenameModal({ isOpen, toggle, family, renameFamily }) {
    const [nameString, setNameString] = useState("");

    const getFamilyName = () => {
        if (!family) return "";
        return family.familyName;
    };

    const handleChange = (event) => {
        setNameString(event.target.value);
    };

    const handleSubmit = (event) => {
        renameFamily(family.familyId, nameString);
    };

    return (
        <Modal
            className="checklist-rename-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Change Family Name</ModalHeader>
            <ModalBody>
                <div>
                    <Label>Old Name:</Label>
                    <div>{getFamilyName()}</div>
                </div>
                <div>
                    <Label>New Name:</Label>
                    <Input
                        value={nameString}
                        onChange={handleChange}
                        placeholder="Family name..."
                        invalid={nameString.length === 0}
                    />
                </div>
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={nameString.length === 0}
                >
                    Save
                </Button>
            </ModalBody>
        </Modal>
    );
}

function InstrumentFamilyDeleteModal({ isOpen, toggle, family, deleteFamily }) {
    const getFamilyName = () => {
        if (!family) return "";
        return family.familyName;
    };

    const handleClick = () => {
        if (!family) return;
        deleteFamily(family.familyId);
    };

    return (
        <Modal
            className="checklist-delete-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody>
                <div>
                    {"Are you sure you want to permanently delete "}
                    <b>{getFamilyName()}</b>
                    {"?"}
                </div>
                <div className="checklist-delete-modal-buttons">
                    <Button
                        className="checklist-delete-modal-delete"
                        onClick={handleClick}
                    >
                        Delete
                    </Button>
                    <Button
                        className="checklist-delete-modal-cancel"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ChecklistInstrumentFamilyAdmin;
