import React, { useEffect, useRef, useState } from "react";
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM.js';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { fromLonLat } from 'ol/proj';
import { Extent, MouseWheelZoom, defaults } from 'ol/interaction';
import { defaults as defaultControls } from 'ol/control.js';
import { Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from "ol/style/Stroke";
import Overlay from "ol/Overlay";
import { Button } from "reactstrap";

import { colors } from "../styles/colors";
import { boundingExtent, buffer, extend } from "ol/extent";

function SiteSelectMap({ matchingSites, selectedSite, setSelectedSite }) {
    const [map, setMap] = useState(null);
    const mapTargetElement = useRef(null);
    const [selectedDot, setSelectedDot] = useState(null);
    const popupRef = useRef(null);

    useEffect(() => {
        if (matchingSites && matchingSites.length > 0 && map === null) {
            initMap();
        } else if (matchingSites && map !== null) {
            const featuresLayer = map.getLayers().array_[1];
            featuresLayer.getSource().clear();
            for (let site of matchingSites) {
                const feature = new Feature({
                    geometry: new Point(fromLonLat([site.longitude, site.latitude], 'EPSG:4326')),
                    siteId: site.siteId
                });
                feature.setId('dashboard-map-dot-' + site.siteId);
                featuresLayer.getSource().addFeature(feature);
                centerMap(map);
            }
        }
    }, [matchingSites]);

    useEffect(() => {
        if (matchingSites && map !== null) {
            if (selectedSite !== 'all') {
                const featuresLayer = map.getLayers().array_[1];
                const selectedLayer = map.getLayers().array_[2];
                selectedLayer.getSource().clear();
                const feature = featuresLayer.getSource().getFeatureById("dashboard-map-dot-" + selectedSite);
                if (feature) {
                    const geometry = feature.getGeometry();
                    const selectedDot = new Feature({
                        geometry: geometry,
                        siteId: feature.get("siteId")
                    });
                    selectedDot.setId('dashboard-map-dot-selected-' + feature.get("siteId"));
                    selectedLayer.getSource().addFeature(selectedDot);
                    map.getView().setCenter(geometry.getCoordinates());
                }
                setSelectedDot(null);
            } else {
                const selectedLayer = map.getLayers().array_[2];
                selectedLayer.getSource().clear();
                centerMap(map);
                setSelectedDot(null);
            }
        }
    }, [selectedSite]);

    const centerMap = (mapElement) => {
        var coordinates = [];
        for (let site of matchingSites) {
            coordinates.push([site.longitude, site.latitude]);
        }
        var bounds = boundingExtent(coordinates);
        bounds = buffer(bounds, 2);
        mapElement.getView().fit(bounds, mapElement.getSize()); 
    }

    const initMap = () => {
        const newMap = new Map({
            interactions: defaults({ dragPan: true, mouseWheelZoom: false, pinchRotate: false, pinchZoom: false }).extend([
                new MouseWheelZoom({
                    useAnchor: true
                })
            ]),
            controls: defaultControls({ zoom: true, rotate: false, attribution: false }),
            layers: [
                new TileLayer({ source: new OSM() }),
            ],
            view: new View({
                center: [-105.084419, 40.585258],
                projection: 'EPSG:4326',
                zoom: 5,
                minZoom: 2.5,
                maxZoom: 18,
            }),
            style: { borderRadius: '6px' }
        });

        const dotStyle = new Style({
            image: new Circle({
                radius: 8,
                fill: new Fill({ color: colors['ars-blue-400'] }),
                stroke: new Stroke({ color: colors['ars-neutral-200'], width: 1.5 })
            })
        });

        const selectedDotStyle = new Style({
            image: new Circle({
                radius: 8,
                fill: new Fill({ color: colors['ars-green-400'] }),
                stroke: new Stroke({ color: colors['ars-neutral-200'], width: 1.5 })
            })
        });

        const highlightDotStyle = new Style({
            image: new Circle({
                radius: 8,
                fill: new Fill({ color: colors['ars-blue-500'] }),
                stroke: new Stroke({ color: colors['ars-neutral-200'], width: 1.5 })
            })
        })

        const featuresArr = [];
        for (let site of matchingSites) {
            const feature = new Feature({
                geometry: new Point(fromLonLat([site.longitude, site.latitude], 'EPSG:4326')),
                siteId: site.siteId
            });
            feature.setId('dashboard-map-dot-' + site.siteId);
            featuresArr.push(feature);
        }

        const featuresLayer = new VectorLayer({
            source: new VectorSource({
                features: featuresArr
            }),
            style: dotStyle
        });
        const selectedLayer = new VectorLayer({
            source: new VectorSource({
                features: []
            }),
            style: selectedDotStyle
        });
        const highlightLayer = new VectorLayer({
            source: new VectorSource({
                features: []
            }),
            style: (feature) => {
                const selectedSites = selectedLayer.getSource().getFeatures();
                if (selectedSites.length > 0) {
                    if (selectedSites[0].get("siteId") === feature.get("siteId")) {
                        return new Style({
                            image: new Circle({
                                radius: 8,
                                fill: new Fill({ color: colors['ars-green-600'] }),
                                stroke: new Stroke({ color: colors['ars-neutral-200'], width: 1.5 })
                            })
                        })
                    }
                }
                return highlightDotStyle;
            }
        });

        newMap.addLayer(featuresLayer);
        newMap.addLayer(selectedLayer);
        newMap.addLayer(highlightLayer);
        newMap.setTarget(mapTargetElement.current || "");

        if (selectedSite !== 'all') {
            const feature = featuresLayer.getSource().getFeatureById("dashboard-map-dot-" + selectedSite);
            if (feature) {
                selectedLayer.getSource().clear();
                const geometry = feature.getGeometry();
                const selectedDot = new Feature({
                    geometry: geometry,
                    siteId: feature.get("siteId")
                });
                selectedDot.setId('dashboard-map-dot-selected-' + feature.get("siteId"));
                selectedLayer.getSource().addFeature(selectedDot);
            }
        }

        const overlay = new Overlay({
            id: "dashboard-map-overlay",
            element: popupRef.current
        });
        newMap.addOverlay(overlay);

        newMap.on("pointermove", (event) => {
            highlightLayer.getSource().clear();
            var feature = newMap.forEachFeatureAtPixel(event.pixel, (feature) => feature);
            if (feature) {
                newMap.getTargetElement().style.cursor = 'pointer';
                const geometry = feature.getGeometry();
                const highlight = new Feature({
                    geometry: geometry,
                    siteId: feature.get("siteId")
                });
                highlight.setId('dashboard-map-dot-highlight-' + feature.get("siteId"));
                highlightLayer.getSource().addFeature(highlight);
            } else {
                newMap.getTargetElement().style.cursor = '';
            }
        });

        newMap.on("click", (event) => {
            var feature = newMap.forEachFeatureAtPixel(event.pixel, (feature) => feature);
            if (feature) {
                const geometry = feature.getGeometry();
                const highlight = highlightLayer.getSource().getFeatureById('dashboard-map-dot-highlight-' + feature.get("siteId"));
                highlightLayer.getSource().removeFeature(highlight);
                newMap.getOverlayById("dashboard-map-overlay").setPosition(geometry.getCoordinates());
                console.log(overlay.getPosition());
                const selectedDot = new Feature({
                    geometry: geometry,
                    siteId: feature.get("siteId")
                });
                setSelectedDot(feature.get("siteId"));
                selectedDot.setId('dashboard-map-dot-selected-' + feature.get("siteId"));
                selectedLayer.getSource().clear();
                selectedLayer.getSource().addFeature(selectedDot);
                newMap.getView().setCenter(geometry.getCoordinates());
            } else {
                setSelectedDot(null);
            }
        });

        centerMap(newMap);

        setMap(newMap);
        return () => newMap.setTarget("");
    }

    return (
        <div id='site-select-map'>
            <div id='site-select-map-element' ref={mapTargetElement} />
            <MapPopup elRef={popupRef} matchingSites={matchingSites} selectedSite={selectedSite} setSelectedSite={setSelectedSite} selectedDot={selectedDot} hidden={selectedDot === null} />
        </div>
    )
}

function MapPopup({ selectedSite, setSelectedSite, selectedDot, matchingSites, elRef, hidden }) {
    const getSiteId = () => {
        return selectedDot;
    }

    const getSiteName = () => {
        if (matchingSites) {
            const site = matchingSites.filter(site => site.siteId === selectedDot);
            if (site.length > 0) {
                return <span><span className="site-abbr">{site[0].siteAbbr}</span>{' - ' + site[0].fullName}</span>;
            }
        }
        return null;
    }

    const handleClick = () => {
        const siteId = getSiteId();
        if (siteId) {
            setSelectedSite(getSiteId());
        }
    }

    return (
        <div id='dashboard-map-popup' ref={elRef} hidden={hidden}>
            <div id='dashboard-map-popup-name'>{getSiteName()}</div>
            <Button id='dashboard-map-popup-button' onClick={handleClick} disabled={selectedSite === selectedDot}>Select</Button>
        </div>
    )
}

export default SiteSelectMap;