const unitNames = {
    "μg/m³": "Micrograms per Cubic Meter",
    "%": "Percent",
    "ppb": "Parts per Billion",
    "in/hr": "Inches per Hour",
    "mph": "Miles per Hour",
    "°F": "Degrees Fahrenheit",
    "°": "Degrees",
    "W/m²": "Watts per Square Meter",
    "mi": "Miles",
    "inHg": "Inches of Mercury",
    "mm/hr": "Millimeters per Hour",
    "m/s": "Meters per Second",
    "°C": "Degrees Celsius",
    "km": "Kilometers",
    "mmHg": "Millimeters of Mercury"
}

const parameterOrder = [
    "Ozone",
    "Particulate Matter",
    "Particulate Matter (Low Cost Sensor)",
    "Carbon Monoxide",
    "Nitric Oxide",
    "Nitrogen Dioxide",
    "Nitrogen Dioxide plus Nitric Acid",
    "Oxides of Nitrogen",
    "Sulfur Dioxide",
    "Barometric Pressure",
    "Humidity",
    "Peak Wind Speed",
    "Precipitation",
    "Solar Radiation",
    "Scalar Wind Direction",
    "Scalar Wind Speed",
    "Temperature",
    "Vector Wind Direction",
    "Vector Wind Speed",
    "Visibility"
]

export function getUnitString(parameter) {
    const unit = (parameter.fields.length === 0) ? parameter.aqi.unit : parameter.fields[0].unit;
    return `${unitNames[unit]} (${unit})`;
}

export function sortParameters(a, b) {
    const aIndex = parameterOrder.indexOf(a);
    const bIndex = parameterOrder.indexOf(b);
    
    if (aIndex === -1 && bIndex === -1) return a.localeCompare(b);
    else if (aIndex === -1) return 1;
    else if (bIndex === -1 ) return -1;

    if (parameterOrder.indexOf(a) > parameterOrder.indexOf(b)) return 1;
    else if (parameterOrder.indexOf(a) < parameterOrder.indexOf(b)) return -1;
    else return 0
}