export const aqiFields = {
    // Scales are populated with the bottom value of each segment of the range. Should be a maximum of 6 values (6 different levels of concern)
    Ozone: {
        scale: [0, 55, 71, 86, 106],
        unit: "ppb",
        step: 1,
    },
    "Particulate Matter": {
        scale: [0, 12.1, 35.5, 55.5, 150.5, 250.5],
        unit: "µg/m³",
        step: 0.1,
    },
    "Low Cost Sensor Particulate Matter": {
        scale: [0, 51, 101, 151, 201, 301],
        step: 1,
    },
    AQI: {
        scale: [0, 51, 101, 151, 201, 301],
        step: 1,
    },
};

export const aqiIndeces = [
    {
        text: "Unknown",
        abbr: "unknown",
        color: "Gray",
        desc: "AQI value unknown.",
        index: 0,
    },
    {
        text: "Good",
        abbr: "good",
        color: "Green",
        desc: "No cautionary statement.",
        index: 1,
    },
    {
        text: "Moderate",
        abbr: "moderate",
        color: "Yellow",
        desc: "Unusually sensitive people should consider reducing prolonged or heavy exertion outdoors.",
        index: 2,
    },
    {
        text: "Unhealthy for Sensitive Groups",
        abbr: "unhealthy-sensitive",
        color: "Orange",
        desc: "Children, older adults, active people, and people with lung disease (such as asthma) should reduce prolonged or heavy exertion outdoors.",
        index: 3,
    },
    {
        text: "Unhealthy",
        abbr: "unhealthy",
        color: "Red",
        desc: "Children, older adults, active people, and people with lung disease (such as asthma) should avoid prolonged or heavy exertion outdoors. Everyone else should reduce prolonged or heavy exertion outdoors.",
        index: 4,
    },
    {
        text: "Very Unhealthy",
        abbr: "very-unhealthy",
        color: "Purple",
        desc: "Children, older adults, active people, and people with lung disease (such as asthma) should avoid all outdoor exertion. Everyone else should avoid prolonged or heavy exertion outdoors.",
        index: 5,
    },
    {
        text: "Hazardous",
        abbr: "hazardous",
        color: "Maroon",
        desc: "Everyone should avoid all physical activity outdoors. Children, older adults, and people with heart or lung disease (such as asthma) should remain indoors and keep activity levels low. Follow tips for keeping particle levels low indoors.",
        index: 6,
    },
];

export function getAQIRating(value, scale) {
    for (let i = scale.length - 1; i >= 0; i--) {
        if (value >= scale[i]) return aqiIndeces[i];
    }
}
