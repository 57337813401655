import React from "react";

import "../styles/SortControls.css";
import { default as utilIcons } from "../resources/utilIcons";
import { colors } from "../styles/colors";

function SortControls({ size, index, updateFn }) {
    const handleUp = (event) => {
        if (!updateFn) return;
        event.stopPropagation();
        updateFn(index, index - 1);
    };

    const handleDown = (event) => {
        if (!updateFn) return;
        event.stopPropagation();
        updateFn(index, index + 1);
    };

    return (
        <div className={"sort-controls" + (size ? " " + size : "")}>
            <button className="sort-controls-button up" onClick={handleUp}>
                {utilIcons.caret(colors["ars-neutral-400"], {
                    rotate: "180deg",
                })}
            </button>
            <button className="sort-controls-button down" onClick={handleDown}>
                {utilIcons.caret(colors["ars-neutral-400"])}
            </button>
        </div>
    );
}

export default SortControls;