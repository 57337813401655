export const colors = {
    'ars-blue-100': '#1f3338',
    'ars-blue-200': '#21404F',
    'ars-blue-300': '#2D545F',
    'ars-blue-400': '#427A8A',
    'ars-blue-500': '#4890A0',
    'ars-blue-600': '#4DA6B5',
    'ars-blue-700': '#70BCC8',
    'ars-blue-800': '#93D2DB',
    'ars-blue-900': '#B5E7ED',

    'ars-green-100': '#294721',
    'ars-green-200': '#325628',
    'ars-green-300': '#3D6830',
    'ars-green-400': '#4A7D3A',
    'ars-green-500': '#56A53D',
    'ars-green-600': '#61CC40',
    'ars-green-700': '#93DC7C',
    'ars-green-800': '#A8E395',
    'ars-green-900': '#BCE9AE',

    'ars-red-100': '#3B0D0F',
    'ars-red-200': '#480F12',
    'ars-red-300': '#571316',
    'ars-red-400': '#6A171A',
    'ars-red-500': '#811B20',
    'ars-red-600': '#9C2126',
    'ars-red-700': '#F8333C',
    'ars-red-800': '#FA575E',
    'ars-red-900': '#FA747A',

    'ars-neutral-100': '#0D1011',
    'ars-neutral-200': '#2B2E2F',
    'ars-neutral-300': '#484B4C',
    'ars-neutral-400': '#66686A',
    'ars-neutral-500': '#838587',
    'ars-neutral-600': '#A1A3A5',
    'ars-neutral-700': '#BEC0C2',
    'ars-neutral-800': '#DCDDE0',
    'ars-neutral-900': '#F9FAFD',

    'ars-error': '#F8333C',

    'aqi-good': '#00A849',
    'aqi-moderate': '#ffd000',
    'aqi-unhealthy-sensitive': '#E07400',
    'aqi-unhealthy': '#C20021',
    'aqi-very-unhealthy': '#8F3F97',
    'aqi-hazardous': '#5E0023',
    'aqi-unknown': '#BEC0C2'
}