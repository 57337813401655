import { default as computer } from '../resources/computer-svgrepo-com.svg';
import { default as binary } from '../resources/binary-svgrepo-com.svg';

export const instrumentTypes = {
    "Computer": computer,
    "O3 Analyzer": computer,
    "Datalogger": binary,
    "Shelter": computer,
    "CO Analyzer": computer,
    "Aerosol Monitor": computer,
    "Wind Direction": computer,
    "PM10 & PM2.5": computer,
    "SO2 Analyzer": computer,
    "Gas Regulator": computer,
    "NOy Analyzer": computer,
    "Precipitation": computer,
    "Barometric Pressure": computer,
    "Mass Flow Controller": computer,
    "Wind Monitor": computer,
    "PM10": computer,
    "Hydrocarbon Analyzer": computer,
    "NADP Sampler": computer,
    "Modem": computer,
    "AT/RH": computer,
    "Gas Handling": computer,
    "Nephelometer": computer,
    "Tower": computer,
    "Temperature": computer,
    "Gas Dilution Calibrator": computer,
    "O3 Station Reference": computer,
    "CHARGE CONTROLLER": computer,
    "Logger Peripheral": computer,
    "Zero-Air Supply": computer,
    "Solar Radiation": computer,
    "Gas Cylinders": computer,
    "O3 Calibrator": computer,
    "Wind Speed": computer,
    "Relative Humidity": computer,
    "NOx Analyzer": computer,
    "Infrastructure": computer,
    "Computer Peripheral": computer,
    "Ambient Temperature": computer

}

export function getInventoryIcon(typeString) {
    if (typeString in instrumentTypes) return instrumentTypes[typeString];
    return computer;
}