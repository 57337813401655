import React, { useContext, useEffect, useState } from "react";
import { Input } from "reactstrap";

import { RestContext } from "../../../App";
import { CHECKLIST_URL } from "../../../serverConfig";
import { formatDatetime } from "../../../util/timeUtils";

function ChecklistArchive({}) {
    const [checklistList, setChecklistList] = useState([]);
    const [enabledChecklists, setEnabledChecklists] = useState({});
    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/all", (response) => {
            let newEnabledChecklists = {};
            for (let checklist of response.data.checklists) {
                newEnabledChecklists[checklist.checklistId] = checklist.enabled;
            }
            setEnabledChecklists(newEnabledChecklists);
            setChecklistList(response.data.checklists);
        })
    }, []);

    const toggleChecklist = (checklistId) => {
        sendPostRequest(CHECKLIST_URL + "/toggle/" + checklistId, null); 
        const newEnabledChecklists = {...enabledChecklists, [checklistId]: !enabledChecklists[checklistId]};
        setEnabledChecklists(newEnabledChecklists);
    }

    const mapChecklists = () => {
        if (!checklistList) return null;
        return checklistList.map((checklist, index) => 
            <tr>
                <td>{checklist.checklistName}</td>
                <td>{formatDatetime(checklist.lastEdited)}</td>
                <td><Input type="checkbox" checked={enabledChecklists[checklist.checklistId]} onClick={() => toggleChecklist(checklist.checklistId)} readOnly /></td>
            </tr>
        )
    }

    return (
        <div id="checklist-archive">
            <table id="checklist-container">
                <thead>
                    <tr>
                        <th>Checklist Name</th>
                        <th>Last Edited</th>
                        <th>Enabled</th>
                    </tr>
                </thead>
                <tbody>
                    {mapChecklists()}
                </tbody>
            </table>
        </div>
    )
}

export default ChecklistArchive;