import React, { useContext } from "react";
import { RestContext } from "../../App";
import { DASHBOARD_URL } from "../../serverConfig";
import { Button } from "reactstrap";

function OtherAdmin({}) {
    const { sendGetRequest } = useContext(RestContext);

    const refreshParameters = () => {
        sendGetRequest(DASHBOARD_URL + "/parameters/refresh");
    }

    return (
        <div id="other-admin">
            <Button id="refresh-parameters-button" onClick={refreshParameters}>Refresh Parameters</Button>
        </div>
    )
}

export default OtherAdmin;