import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import '../styles/DocumentPreview.css';
import { default as downloadIcon } from '../resources/download-svgrepo-com.svg';

import { DOC_URL } from "../serverConfig";

function DocumentPreview({doc, isOpen, toggle}) {
    const [document, setDocument] = useState(null);

    useEffect(() => {
        if (doc !== undefined) {
            setDocument(`${DOC_URL}/preview/${doc.docId}`);
        }
    }, [doc]);

    const getDownloadLink = (doc) => {
        if (doc === undefined) return null;
        return DOC_URL + '/download/' + doc.docId;
    }

    const renderContent = () => {
        if (doc === undefined) return null;
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(doc.extension)) {
            return (
                <div id='document-preview-image-wrapper'>
                    <img id='document-preview-image' src={document} alt='Preview' />
                </div>
            )
        } else if (['heic', 'heif'].includes(doc.extension)) {
            return (
                <div id='document-preview-not-available'>
                    <p>Document type not supported by browser.</p>
                </div>
            )
        } else {
            return <iframe title='document-preview-frame' src={document} />
        }
    }

    return (
        <Modal id='document-preview-modal' isOpen={isOpen} toggle={toggle}>
            <ModalHeader id="document-preview-header" toggle={toggle}>
                <span>Preview</span>
            </ModalHeader>
            <ModalBody id="document-preview-body">
                <div id='document-preview-wrapper'>
                    {renderContent()}
                </div>
                <br />
                <a href={getDownloadLink(doc)}><Button className='download-button'>Download <img src={downloadIcon} className='inline-svg' alt="download" /></Button></a>
            </ModalBody>
        </Modal>
    )
}

export default DocumentPreview;