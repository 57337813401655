import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner, Tooltip } from 'reactstrap';

import "../styles/ForgotPassword.css";

import { AUTH_URL, corsConfig } from '../serverConfig';
import { RestContext } from '../App';
import { checkMobile } from '../util/globals';

export function ForgotPassword({ toggle, isOpen }) {
    const [email, setEmail] = useState();
    const [code, setCode] = useState();

    const renderBody = () => {
        if (email === undefined) return <RequestCode setEmail={setEmail} />;
        else if (code === undefined) {
            return <ConfirmCode email={email} setCode={setCode} />
        } else {
            return <ResetPassword email={email} code={code} toggleModal={toggle} />
        }
    }

    const handleClosed = () => {
        setEmail();
        setCode();
    }

    return (
        <Modal toggle={toggle} className='forgot-password shadow-lg' isOpen={isOpen} onClosed={handleClosed}>
            <ModalHeader toggle={toggle}><span className='forgot-password-header'>Reset Password</span></ModalHeader>
            <ModalBody>
                {renderBody()}
            </ModalBody>
        </Modal>
    );
}

function RequestCode({ setEmail }) {
    const [emailString, setEmailString] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [errorStatus, setErrorStatus] = useState();

    const { sendPostRequest } = useContext(RestContext);

    const handleSubmit = () => {
        setWaiting(true);
        const formData = new FormData();
        formData.set("email", emailString);
        sendPostRequest(AUTH_URL + '/requestReset', formData, (response) => {
            setWaiting(false);
            setEmail(emailString);
        }, (error) => {
            setErrorStatus(error.response.status);
            setWaiting(false);
        });
    }

    const handleChange = (event) => {
        setEmailString(event.target.value);
    }

    const validEmail = () => {
        const email = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return email.test(emailString);
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter' && validEmail()) handleSubmit();
    }

    return (
        <div>
            <p>Enter your email to reset your password.</p>
            <Label for='forgot-password-email' >Email:</Label>
            <Input
                id='request-reset-email'
                type='email'
                name='forgot-password-email'
                value={emailString}
                onChange={handleChange}
                onKeyDown={submitOnEnter}
                invalid={errorStatus !== undefined || (emailString !== '' && !validEmail())}
                onFocus={() => setErrorStatus()}
            />
            <RequestTooltip target='request-reset-email' errorStatus={errorStatus} isOpen={errorStatus !== undefined} />
            <br />
            <Button className='submit-button' onClick={handleSubmit} disabled={!validEmail()}>{waiting ? <Spinner size={"sm"} /> : 'Submit'}</Button>
        </div>
    )
}

function ConfirmCode({ email, setCode }) {
    const [verificationCode, setVerificationCode] = useState('');
    const [invalidCode, setInvalidCode] = useState(false);
    const [errorStatus, setErrorStatus] = useState();

    const { sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        if (verificationCode.length === 8) {
            const code = verificationCode;
            sendPostRequest(AUTH_URL + '/confirmReset', { 'email': email, 'code': code }, (response) => {
                setCode(code);
            }, (error) => {
                setInvalidCode(true);
                setErrorStatus(error.response.status);
            });
        } else {
            if (invalidCode) {
                setInvalidCode(false);
                setErrorStatus();
            }
        }
    }, [verificationCode]);

    const handleChange = (event) => {
        if (event.target.value.length <= 8) setVerificationCode(event.target.value.toUpperCase());
    }

    const getWidth = () => {
        return invalidCode ? '8.5em' : '8em';
    }

    return (
        <div>
            <p>A verification code as been sent to <b>{email}</b>.</p>
            <p>Note: It may take a few moments for the message to arrive in your inbox.</p>
            <div style={{ textAlign: 'center' }}>
                <p><b>Enter your verification code below:</b></p>
                <Input
                    id='verification-code'
                    type='text'
                    name='verification-code'
                    value={verificationCode}
                    onChange={handleChange}
                    invalid={invalidCode}
                    style={{ width: getWidth() }}
                />
                <ConfirmTooltip target='verification-code' errorStatus={errorStatus} isOpen={errorStatus !== undefined} />
            </div>
            <br />
        </div>
    )
}

function ResetPassword({ email, code, toggleModal }) {
    const [activeField, setActiveField] = useState('');
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [waiting, setWaiting] = useState(false);

    const { sendPostRequest } = useContext(RestContext);

    const changePassword = (event) => {
        setPassword(event.target.value);
    }

    const changeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    }

    const validatePassword = () => {
        if (password === undefined) return false;
        const upper = /[A-Z]/;
        const lower = /[a-z]/;
        const number = /[0-9]/;

        return password.length >= 12 && upper.test(password) && lower.test(password) && number.test(password);
    }

    const matchPassword = () => {
        return password == confirmPassword;
    }

    const canSubmit = () => {
        return validatePassword() && matchPassword();
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter' && canSubmit()) handleSubmit(event);
    }

    const handleSubmit = () => {
        if (canSubmit()) {
            setWaiting(true);
            sendPostRequest(AUTH_URL + '/reset', { 'email': email, 'code': code, 'password': password }, (response) => {
                setWaiting(false);
                toggleModal();
            }, (error) => {
                setWaiting(false);
            });
        }
    }

    return (
        <div>
            <p><b>Enter New Password</b></p>
            <Input valid={password !== undefined && validatePassword()}
                invalid={password !== undefined && !validatePassword()}
                id='reset-password'
                name='password'
                type='password'
                placeholder='Password'
                onChange={changePassword}
                onKeyDown={submitOnEnter}
                onFocus={() => setActiveField('password')}
                onBlur={() => setActiveField('')}
                autoComplete='off'
            />
            <PasswordTooltip password={password} isOpen={activeField === 'password'} target={'reset-password'} />
            <br />
            <Input valid={confirmPassword !== undefined && matchPassword()}
                invalid={confirmPassword !== undefined && !matchPassword()}
                id='reset-confirm-password'
                name='confirmPassword'
                type='password'
                placeholder='Retype Password'
                onChange={changeConfirmPassword}
                onKeyDown={submitOnEnter}
                onFocus={() => setActiveField('confirmPassword')}
                onBlur={() => setActiveField('')}
                autoComplete='off'
            />
            <ConfirmPasswordTooltip password={password} confirmPassword={confirmPassword} isOpen={activeField === 'confirmPassword'} target={'reset-confirm-password'} />
            <br />
            <Button className='submit-button' onClick={handleSubmit} disabled={!canSubmit()}>{waiting ? <Spinner size={"sm"} /> : 'Change Password'}</Button>
        </div>
    )
}

function RequestTooltip({ errorStatus, target, isOpen }) {

    const getTooltipText = () => {
        if (errorStatus === 404) {
            return 'No account found with that email. Please check your spelling and try again.'
        }
    }

    return (
        <Tooltip target={target} isOpen={isOpen} placement='right'>
            {getTooltipText()}
        </Tooltip>
    )
}

function ConfirmTooltip({ errorStatus, target, isOpen }) {

    const getTooltipText = () => {
        switch (errorStatus) {
            case 400:
                return 'Invalid code. Double-check your email and try again.';
            case 401:
                return 'Code expired. Request a new code to reset password.';
            case 404:
                return 'No password reset request exists for this account. Request a new code to reset password.';
            case 500:
                return 'Internal server error. Please try again later or contact blm-web@air-resource.com for assistance.';
            default:
                return null;
        }
    }

    return (
        <Tooltip target={target} isOpen={isOpen} placement='right'>
            {getTooltipText()}
        </Tooltip>
    )
}

function PasswordTooltip({ password, isOpen, target }) {
    const validLength = () => {
        if (password === undefined) return false;
        return password.length >= 12;
    }

    const hasUpper = () => {
        if (password === undefined) return false;
        const upper = /[A-Z]/;
        return upper.test(password);
    }

    const hasLower = () => {
        if (password === undefined) return false;
        const lower = /[a-z]/;
        return lower.test(password);
    }

    const hasNumber = () => {
        if (password === undefined) return false;
        const number = /[0-9]/;
        return number.test(password);
    }

    const hasSpecial = () => {
        if (password === undefined) return false;
        const number = /[#?!@$%^&*-]/;
        return number.test(password);
    }

    return (
        <Tooltip placement={checkMobile() ? 'bottom' : 'right'} isOpen={isOpen} target={target}>
            <ul>
                <li className={validLength() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must be at least 12 characters.</li>
                <li className={hasUpper() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one uppercase letter.</li>
                <li className={hasLower() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one lowercase letter.</li>
                <li className={hasNumber() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one number.</li>
                <li className={hasSpecial() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one special character ( #?!@$%^&*- ).</li>
            </ul>
        </Tooltip>
    )
}

function ConfirmPasswordTooltip({ password, confirmPassword, isOpen, target }) {
    const passwordsMatch = () => {
        if (password === undefined || confirmPassword === undefined) return false;
        return password === confirmPassword;
    }

    return (
        <Tooltip placement={checkMobile() ? 'bottom' : 'right'} isOpen={isOpen} target={target}>
            <ul>
                <li className={passwordsMatch() ? 'tooltip-valid' : 'tooltip-invalid'}>Passwords must match.</li>
            </ul>
        </Tooltip>
    )
}