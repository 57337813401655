import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Label } from "reactstrap";

import "../styles/MultiSelect.css";

function MultiSelect({
    data,
    selected,
    setSelected,
    valField,
    textField,
    groupField = null,
    sortFn,
    filterFn, // (Optional) Function that should return true if an item should be shown in the option list and false if it should not be shown
    className,
    name,
    onChange,
    SelectedComponent,
    ...props
}) {
    const [searchString, setSearchString] = useState("");
    const [active, setActive] = useState();
    const ref = useRef(null);
    const optionRef = useRef(null);
    const selectedRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current) {
                if (
                    !ref.current.contains(event.target) &&
                    event.target.id !== "site-select"
                ) {
                    setActive();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("focusin", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleClickOutside);
        };
    }, [ref]);

    const getVal = (item) => {
        if (!item) return null;
        return valField ? item[valField] : item;
    }

    const getText = (item) => {
        if (!item) return null;
        if (textField) {
            return item[textField];
        } else {
            return valField ? item[valField] : item;
        }
    }

    const getMatchingList = () => {
        let matchingData;
        if (searchString === "") {
            matchingData = data;
        } else {
            matchingData = data.filter((item) => checkMatch(item));
        }
        if (sortFn) {
            return matchingData.sort(sortFn);
        } else {
            return matchingData.sort((a, b) =>
                getText(a).localeCompare(getText(b))
            );
        }
    };

    const checkMatch = (item) => {
        if (searchString === "") return true;
        const regex = new RegExp(searchString, "i");
        return (
            (getText(item) && getText(item).match(regex) !== null) ||
            (groupField
                ? item[groupField] && item[groupField].match(regex) !== null
                : false)
        );
    };

    const sortGroups = (a, b) => {
        if (a === "null") return -1;
        else if (b === "null") return 1;
        else return a.localeCompare(b);
    };

    const mapData = () => {
        if (!data || !selected) return null;
        const matchingList = getMatchingList();
        if (groupField) {
            let groups = {};
            matchingList.map((item, index) => {
                if (filterFn && !filterFn(item)) {
                    return;
                }
                if (selected.some(selectedItem => getVal(selectedItem) === getVal(item))) {
                    return;
                }
                let group = item[groupField];
                if (!(group in groups)) {
                    groups[group] = [];
                }
                groups[group].push(
                    <div
                        className={
                            "multiselect-option" +
                            (active && getVal(active) === getVal(item)
                                ? " active"
                                : "")
                        }
                        value={getVal(item)}
                        onClick={(event) => handleClick(event, item)}
                        onMouseDown={handleMouseDown}
                    >
                        {getText(item)}
                    </div>
                );
            });
            let rows = [];
            for (let group of Object.keys(groups).sort(sortGroups)) {
                if (group === "null") {
                    rows = [...rows, ...groups[group]];
                } else {
                    rows = [
                        ...rows,
                        <div className="multiselect-group-name">{group}</div>,
                        ...groups[group],
                    ];
                }
            }
            return rows;
        } else {
            return matchingList.map((item, index) => {
                if (filterFn && !filterFn(item)) {
                    return;
                }
                if (selected.some(selectedItem => getVal(selectedItem) === getVal(item))) {
                    return;
                } else {
                    return (
                        <div
                            key={"option-" + index}
                            className={
                                "multiselect-option" +
                                (active && getVal(active) === getVal(item)
                                    ? " active"
                                    : "")
                            }
                            value={getVal(item)}
                            onClick={(event) => handleClick(event, item)}
                            onMouseDown={handleMouseDown}
                        >
                            {getText(item)}
                        </div>
                    );
                }
            });
        }
    };

    const mapSelected = () => {
        if (!selected) return null;
        if (SelectedComponent) {
            return selected.map((item, index) => (
                <SelectedComponent
                    key={"option-" + index}
                    className={
                        (active && getVal(active) === getVal(item)
                            ? " active"
                            : "")
                    }
                    item={item}
                    value={getVal(item)}
                    onClick={(event) => handleClick(event, item)}
                    onMouseDown={handleMouseDown}
                />
            ));
        } else {
            return selected.map((item, index) => (
                <div
                    key={"option-" + index}
                    className={
                        "multiselect-option" +
                        (active && getVal(active) === getVal(item)
                            ? " active"
                            : "")
                    }
                    value={getVal(item)}
                    onClick={(event) => handleClick(event, item)}
                    onMouseDown={handleMouseDown}
                >
                    {getText(item)}
                </div>
            ));
        }
        
    };

    const handleMouseDown = (event) => {
        if (event.detail > 1) {
            event.preventDefault();
        }
    };

    const handleClick = (event, item) => {
        setActive(item);
        const target = event.target;
        target.focus();
        if (
            target.offsetTop >=
            target.parentNode.scrollTop + target.parentNode.offsetHeight
        ) {
            target.scrollIntoView(false);
        } else if (target.offsetTop < target.parentNode.scrollTop) {
            target.scrollIntoView(true);
        }
    };

    const handleKey = (event) => {
        switch (event.key) {
            case "Enter":
                handleOptionEnter(event);
                break;
            case "ArrowDown":
                handleArrowKey(event);
                break;
            case "ArrowUp":
                handleArrowKey(event);
                break;
            default:
                break;
        }
    };

    const handleOptionEnter = (event) => {
        if (event.key === "Enter") {
            if (
                optionRef.current &&
                optionRef.current === event.currentTarget
            ) {
                moveRight(event);
            } else if (
                selectedRef.current &&
                selectedRef.current === event.currentTarget
            ) {
                moveLeft(event);
            }
        }
    };

    const handleDoubleClick = (event) => {
        if (optionRef.current && optionRef.current === event.currentTarget) {
            moveRight(event);
        } else if (
            selectedRef.current &&
            selectedRef.current === event.currentTarget
        ) {
            moveLeft(event);
        }
    };

    const handleArrowKey = (event) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            if (
                !active ||
                (selectedRef.current &&
                    event.currentTarget === selectedRef.current &&
                    !selected.includes(active)) ||
                (optionRef.current &&
                    event.currentTarget === optionRef.current &&
                    selected.includes(active))
            ) {
                const nextOption = findFirstOption(event.currentTarget);
                if (nextOption) nextOption.click();
            } else {
                const activeElement =
                    event.currentTarget.querySelector(".active");
                const activeIndex = Array.prototype.indexOf.call(
                    event.currentTarget.children,
                    activeElement
                );
                let nextOption = findNextOption(
                    event.currentTarget,
                    activeIndex
                );
                if (nextOption) nextOption.click();
            }
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            if (
                !active ||
                (selectedRef.current &&
                    event.currentTarget === selectedRef.current &&
                    active &&
                    !selected.includes(active)) ||
                (optionRef.current &&
                    event.currentTarget === optionRef.current &&
                    selected.includes(active))
            ) {
                const nextOption = findFirstOption(event.currentTarget);
                if (nextOption) nextOption.click();
            } else {
                const activeElement =
                    event.currentTarget.querySelector(".active");
                const activeIndex = Array.prototype.indexOf.call(
                    event.currentTarget.children,
                    activeElement
                );
                let previousOption = findPreviousOption(
                    event.currentTarget,
                    activeIndex
                );
                if (previousOption) previousOption.click();
            }
        } else {
            return;
        }
    };

    const findNextOption = (element, index) => {
        const children = element.children;
        for (let i = 1; i < children.length; i++) {
            const j = (index + i) % children.length;
            if (children[j].className.match("multiselect-option") && !children[j].className.match("disabled")) {
                return children[j];
            }
        }
        return null;
    };

    const findPreviousOption = (element, index) => {
        const children = element.children;
        for (let i = children.length - 1; i > 0; i--) {
            const j = (index + i) % children.length;
            if (children[j].className.match("multiselect-option") && !children[j].className.match("disabled")) {
                return children[j];
            }
        }
        return null;
    };

    const findFirstOption = (element) => {
        const children = element.children;
        for (let i = 0; i < children.length; i++) {
            if (children[i].className.match("multiselect-option") && !children[i].className.match("disabled")) {
                return children[i];
            }
        }
        return null;
    };

    const findLastOption = (element) => {
        const children = element.children;
        for (let i = children.length - 1; i >= 0; i--) {
            if (children[i].className.match("multiselect-option")) {
                return children[i];
            }
        }
        return null;
    };

    const handleChange = (event) => {
        setSearchString(event.target.value);
    };

    const moveRight = (event) => {
        if (!active || !optionRef.current) return;
        const activeElement = optionRef.current.querySelector(".active");
        const activeIndex = Array.prototype.indexOf.call(
            optionRef.current.children,
            activeElement
        );
        const nextOption = findNextOption(optionRef.current, activeIndex);
        const newSelected = [...selected];
        newSelected.push(active);
        setSelected(newSelected);
        if (onChange) onChange();
        if (nextOption) {
            nextOption.click();
        } else if (selectedRef.current) {
            selectedRef.current.focus();
        }
    };

    const moveLeft = (event) => {
        if (!active || !selectedRef.current) return;
        const activeElement = selectedRef.current.querySelector(".active");
        const activeIndex = Array.prototype.indexOf.call(
            selectedRef.current.children,
            activeElement
        );
        const nextOption = findNextOption(selectedRef.current, activeIndex);
        for (let i in selected) {
            if (selected[i] === active) {
                const newSelected = [...selected];
                newSelected.splice(i, 1);
                setSelected(newSelected);
                if (onChange) onChange();
                break;
            }
        }
        if (nextOption) {
            nextOption.click();
        } else if (optionRef.current) {
            optionRef.current.focus();
        }
    };

    const leftDisabled = () => {
        return !(active && selected.includes(active));
    };

    const rightDisabled = () => {
        return !(active && !selected.includes(active));
    };

    return (
        <div
            className={"multiselect" + (className ? " " + className : "")}
            name={name}
            ref={ref}
            {...props}
        >
            <div className="multiselect-search-wrapper">
                <Input
                    placeholder="Search..."
                    onChange={handleChange}
                    value={searchString}
                />
            </div>
            <div className="multiselect-wrapper">
                <div className="multiselect-container-wrapper">
                    <div
                        className="multiselect-container"
                        tabIndex={0}
                        onKeyDown={handleKey}
                        onDoubleClick={handleDoubleClick}
                        ref={optionRef}
                    >
                        {mapData()}
                    </div>
                </div>
                <div className="multiselect-controls-wrapper">
                    <Button
                        className="multiselect-button"
                        onClick={moveRight}
                        disabled={rightDisabled()}
                        onMouseDown={(event) => event.preventDefault()}
                    >
                        {">"}
                    </Button>
                    <Button
                        className="multiselect-button"
                        onClick={moveLeft}
                        disabled={leftDisabled()}
                        onMouseDown={(event) => event.preventDefault()}
                    >
                        {"<"}
                    </Button>
                </div>
                <div className="multiselect-container-wrapper">
                    <div
                        className="multiselect-container"
                        tabIndex={0}
                        onKeyDown={handleKey}
                        onDoubleClick={handleDoubleClick}
                        ref={selectedRef}
                    >
                        {mapSelected()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultiSelect;
