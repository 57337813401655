import React, { useContext, useState } from "react";
import { Button, Form, Input, Label, Spinner, Tooltip } from "reactstrap";

import '../styles/Signup.css';
import { default as utilIcons } from '../resources/utilIcons';

import PasswordField from "../components/PasswordField";
import { AUTH_URL } from "../serverConfig";
import { checkMobile } from "../util/globals";
import { RestContext } from "../App";
import { colors } from "../styles/colors";

const signupFields = [
    {
        title: "Email",
        name: "email",
        type: "email",
        required: true,
    },
    {
        title: "First Name",
        name: "first-name",
        type: "text",
        required: true,
    },
    {
        title: "Last Name",
        name: "last-name",
        type: "text",
        required: true,
    },
    {
        title: "Password",
        name: "password",
        type: "password",
        required: true,
    },
    {
        title: "Confirm Password",
        name: "confirm-password",
        type: "password",
        required: true,
    },
    {
        title: "Organization",
        name: "organization",
        type: "text",
        required: true,
    },
    {
        title: "Organization Role",
        name: "organization-role",
        type: "text",
        required: true,
    },
    {
        title: "Sites",
        name: "sites-requested",
        type: "textarea",
        required: false,
        placeholder: "Enter all monitoring sites and regions that you would like access to."
    },
    {
        title: "What will you you be using DataView for?",
        name: "comments",
        type: "textarea",
        required: false,
        placeholder: "e.g. Checking current data, communication, etc."
    },
]

const mapValid = () => {
    let validObj = {};
    for (let field of signupFields) {
        validObj[field.name] = (field.required ? false : null); 
    }
    return validObj;
}

function Signup({toggleSignup}) {
    const [formData, setFormData] = useState({});
    const [valid, setValid] = useState(mapValid());
    const [waiting, setWaiting] = useState(false);

    const { sendPostRequest } = useContext(RestContext);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }));
        setValid(values => ({ ...values, [name]: ((value === undefined || value.length > 0) ? validateField(name, value) : (event.target.required ? false : null)) }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        setWaiting(true);
        const payload = {
            email: formData['email'],
            name: formData['first-name'] + ' ' + formData['last-name'],
            password: formData['password'],
            organization: formData['organization'],
            role: formData['organization-role'],
            sitesRequested: formData['sites-requested'],
            comments: formData['comments']
        };
        sendPostRequest(AUTH_URL + '/signup', payload, (response) => {
            setWaiting(false);
            toggleSignup();
        }, (error) => {
            setWaiting(false);
        });
    }

    const validateField = (name, value) => {
        switch (name) {
            case 'email':
                return validEmail(value);
            case 'password':
                return validPassword(value);
            case 'confirm-password':
                return validConfirmPassword(value);
            default:
                return true;
        }
    }

    const validEmail = (value) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    }

    const validPassword = (value) => {
        const match = value === formData['confirm-password'];
        setValid(values => ({ ...values, "passwordsMatch": match }));
        return value !== undefined
            && value.length >= 12
            && /[a-z]/.test(value)
            && /[A-Z]/.test(value)
            && /[0-9]/.test(value)
            && /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/.test(value);
    }

    const validConfirmPassword = (value) => {
        const match = value === formData['password'];
        setValid(values => ({ ...values, "passwordsMatch": match }));
        return value !== undefined
            && value.length >= 12
            && /[a-z]/.test(value)
            && /[A-Z]/.test(value)
            && /[0-9]/.test(value)
            && /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/.test(value);
    }

    const validFormData = () => {
        return !Object.keys(valid).some(key => valid[key] === false);
    }

    const mapFields = () => {
        return signupFields.map((field, index) =>
            <SignupField fieldName={field.name} type={field.type} title={field.title} key={'signup-field-' + index} handleChange={handleChange} value={formData[field.name]} fields={formData} placeholder={field.placeholder} required={field.required} />
        )
    }

    return (
        <div id='signup'>
            <div id='signup-wrapper'>
                <div id='signup-header'>Request Account</div>
                <div id='signup-body'>
                    <Form onSubmit={handleSubmit}>
                        {mapFields()}
                        <p>* Required</p>
                        <p>Note: Please allow 1 to 2 days for request processing.</p>
                        <Button onClick={null} className='submit-button' disabled={!validFormData()}>{waiting ? <Spinner size={'sm'} /> : 'Submit'}</Button>
                    </Form>
                </div>
            </div>
            <Button id='signup-back-button' onClick={toggleSignup} color="primary">{utilIcons.caret(colors['ars-neutral-900'], {rotate: '90deg'})}{'Back'}</Button>
        </div>
    )
}

function SignupField({placeholder, title, type, fieldName, value, handleChange, fields, required}) {
    const getPlaceholder = () => {
        if (placeholder === undefined) return title;
        return placeholder;
    }

    if (type === 'password') {
        return <HiddenSignupField fieldName={fieldName} title={title} handleChange={handleChange} value={value} fields={fields} required={required} />
    } else {
        return (
            <div className="signup-field">
                <Label>{title + (required ? '*' : "")}</Label>
                <Input name={fieldName} type={type} placeholder={getPlaceholder()} onChange={handleChange} value={value} required={required} />
            </div>
        )
    }
}

function HiddenSignupField(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleFocus = () => {
        setTooltipOpen(true);
    }

    const handleBlur = () => {
        setTooltipOpen(false);
    }

    const getTooltip = () => {
        if (props.fieldName === "password") {
            return <PasswordTooltip password={props.value} target={"password-field-" + props.fieldName.replace(' ', '-').toLowerCase() + "-button"} isOpen={tooltipOpen} />
        } else if (props.fieldName === "confirm-password") {
            return <ConfirmPasswordTooltip password={props.fields["password"]} confirmPassword={props.value} target={"password-field-" + props.fieldName.replace(' ', '-').toLowerCase() + "-button"} isOpen={tooltipOpen} />
        } else {
            return null;
        }
    }

    return (
        <div className="signup-field" onFocus={handleFocus} onBlur={handleBlur}>
            <Label>{props.title + (props.required ? '*' : "")}</Label>
            <PasswordField {...props} />
            {getTooltip()}
        </div>
    )
}

function PasswordTooltip({password, isOpen, target}) {
    const validLength = () => {
        if (password === undefined) return false;
        return password.length >= 12;
    }

    const hasUpper = () => {
        if (password === undefined) return false;
        const upper = /[A-Z]/;
        return upper.test(password);
    }

    const hasLower = () => {
        if (password === undefined) return false;
        const lower = /[a-z]/;
        return lower.test(password);
    }

    const hasNumber = () => {
        if (password === undefined) return false;
        const number = /[0-9]/;
        return number.test(password);
    }

    const hasSpecial = () => {
        if (password === undefined) return false;
        const number = /[#?!@$%^&*-]/;
        return number.test(password);
    }

    return (
        <Tooltip placement={checkMobile() ? 'bottom' : "right"} isOpen={isOpen} target={target}>
            <ul>
                <li className={validLength() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must be at least 12 characters.</li>
                <li className={hasUpper() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one uppercase letter.</li>
                <li className={hasLower() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one lowercase letter.</li>
                <li className={hasNumber() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one number.</li>
                <li className={hasSpecial() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one special character ( #?!@$%^&*- ).</li>
            </ul>
        </Tooltip>
    )
}

function ConfirmPasswordTooltip({password, confirmPassword, isOpen, target}) {
    const passwordsMatch = () => {
        if (password === undefined || confirmPassword === undefined) return false;
        return password === confirmPassword;
    }

    return (
        <Tooltip placement={checkMobile() ? 'bottom' : "right"} isOpen={isOpen} target={target}>
            <ul>
                <li className={passwordsMatch() ? 'tooltip-valid' : 'tooltip-invalid'}>Passwords must match.</li>
            </ul>
        </Tooltip>
    )
}

export default Signup;