import React, { useContext, useEffect, useState } from "react";
import { Button, Collapse, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

import SiteTree from "../../components/SiteTree";
import { ADMIN_URL } from "../../serverConfig";
import { RestContext } from "../../App";

function AccountRequests() {
    const [requests, setRequests] = useState({});
    const [roleList, setRoleList] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [openRequest, setOpenRequest] = useState();
    const [sitesModalOpen, setSitesModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    const toggleSitesModalOpen = () => setSitesModalOpen(!sitesModalOpen);

    const openSitesModal = (requestId) => {
        setSitesModalOpen(true);
    }

    useEffect(() => {
        loadAccountRequests();
        loadRoleList();
        loadAgencyList();
    }, []);

    const loadAccountRequests = () => {
        sendGetRequest(ADMIN_URL + '/getPendingUsers', (response) => {
            if (response.data.length > 0) {
                setOpenRequest(response.data[0].requestId);
            } else {
                setOpenRequest();
            }
            let newRequests = {};
            for (let request of response.data) {
                newRequests[request['requestId']] = request;
                newRequests[request['requestId']]['sites'] = new Set();
            }
            setRequests(newRequests);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        });
    }

    const loadRoleList = () => {
        sendGetRequest(ADMIN_URL + '/roles', (response) => {
            setRoleList(response.data);
        });
    }

    const loadAgencyList = () => {
        sendGetRequest(ADMIN_URL + '/agencies', (response) => {
            setAgencyList(response.data);
        });
    }

    const updateRequest = (request) => {
        setRequests(values => ({ ...values, [request.requestId]: request }));
    }

    const mapRequestsToAccountRequest = () => {
        return Object.keys(requests).map((requestId, index) =>
            <AccountRequest
                key={'account-request-' + index}
                requestId={requestId}
                requests={requests}
                roleList={roleList}
                agencyList={agencyList}
                isOpen={openRequest === Number(requestId)}
                setOpenRequest={setOpenRequest}
                updateRequest={updateRequest}
                openModal={openSitesModal}
            />
        );
    }

    const canSubmit = () => {
        return Object.keys(requests).some((id) => requests[id].action === 'Approve' || requests[id].action === 'Reject');
    }

    const getRequestsBody = () => {
        if (Object.keys(requests).length === 0) {
            return <EmptyRow />;
        } else {
            return mapRequestsToAccountRequest();
        }
    }

    const toggleSite = (requestId, siteId) => {
        let newRequest = requests[requestId];
        if (newRequest.sites.has(siteId)) {
            newRequest.sites.delete(siteId);
        } else {
            newRequest.sites.add(siteId);
        }
        setRequests(values => ({ ...values, [requestId]: newRequest }));
    }

    const toggleGroup = (requestId, groupId, subtreeIds) => {
        let newRequest = requests[requestId];
        if (newRequest.sites.has(groupId)) {
            for (let id of subtreeIds) {
                newRequest.sites.delete(id);
            }
        } else {
            for (let id of subtreeIds) {
                newRequest.sites.add(id);
            }
        }
        setRequests(values => ({ ...values, [requestId]: newRequest }));
    }

    const handleSubmit = () => {
        setLoading(true);
        let toSubmit = Object.keys(requests).filter((id) => requests[id].action === 'Approve' || requests[id].action === 'Reject');
        let count = toSubmit.length;
        for (let id of toSubmit) {
            const request = requests[id];
            if (request.action === 'Approve') {
                const payload = {
                    requestId: request.requestId,
                    userRole: request.userRole,
                    userSites: Array.from(request.sites),
                    adminComments: request.adminComments
                }
                console.log(payload);
                sendPostRequest(ADMIN_URL + '/approveUser', payload, (response) => {
                    count--;
                    if (count === 0) {
                        loadAccountRequests();
                    }
                });
            } else if (requests[id].action === 'Reject') {
                const payload = {
                    requestId: request.requestId,
                    adminComments: request.adminComments
                }
                sendPostRequest(ADMIN_URL + '/rejectUser', payload, (response) => {
                    count--;
                    if (count === 0) {
                        loadAccountRequests();
                    }
                });
            }
        }
    }

    return (
        <div id='account-requests-container'>
            <div id='account-requests-body'>
                {getRequestsBody()}
            </div>
            <div id='account-requests-submit-wrapper'>
                <Button className="submit-button" onClick={handleSubmit} disabled={!canSubmit()}>{loading ? <Spinner size='sm' /> : 'Submit'}</Button>
            </div>
            <SiteSelectModal
                toggle={toggleSitesModalOpen}
                isOpen={sitesModalOpen}
                requests={requests}
                requestId={openRequest}
                toggleSite={toggleSite}
                toggleGroup={toggleGroup}
            />
        </div>
    )
}

function AccountRequest({ requests, requestId, updateRequest, agencyList, roleList, isOpen, setOpenRequest, openModal }) {
    const [request, setRequest] = useState(requests[requestId]);

    useEffect(() => {
        setRequest(requests[requestId]);
    }, [requests]);

    const handleChange = (event) => {
        let newRequest = request;
        newRequest[event.target.name] = event.target.value;
        if (event.target.name === 'userRole' && showAgency() && request.userAgency === undefined) {
            newRequest['userAgency'] = 'NPS';
        }
        updateRequest(newRequest);
    }

    const getOrgRole = () => {
        if (request === undefined || request.orgRole === undefined || request.orgRole === null) return null;
        return request.orgRole;
    }

    const getOrganization = () => {
        if (request === undefined || request.organization === undefined || request.organization === null) return null;
        return request.organization;
    }

    const getComments = () => {
        if (request === undefined || request.comments === undefined || request.comments === null) return 'N/A';
        return request.comments;
    }

    const getSites = () => {
        if (request === undefined || request.sitesRequested === undefined || request.sitesRequested === null) return 'N/A';
        return request.sitesRequested;
    }

    const getAdminComments = () => {
        if (request === undefined || request.adminComments === undefined || request.adminComments === null) return '';
        return request.adminComments;
    }

    const getUserEmail = () => {
        if (request === undefined || request.userEmail === undefined) return null;
        return request.userEmail;
    }

    const getUserName = () => {
        if (request === undefined || request.userName === undefined) return null;
        return request.userName;
    }

    const getRole = () => {
        if (request === undefined || request.userRole === undefined) return 'N/A';
        return request.userRole;
    }

    const getRoleShort = () => {
        if (request === undefined || request.userRole === undefined) return 'N/A';
        return request.userRole.substring(5);
    }

    const getAgency = () => {
        if (request === undefined || request.userAgency === undefined) return 'N/A';
        return request.userAgency;
    }

    const mapAgenciesToOption = () => {
        if (agencyList === undefined) return null;
        return agencyList.map((agency, index) =>
            <option key={'agency-select-option-' + index} value={agency.agencyCode}>{agency.agencyCode}</option>
        )
    }

    const mapRolesToOption = () => {
        if (roleList === undefined) return null;
        return roleList.map((role, index) =>
            <option key={'role-select-option-' + index} value={role.roleCode}>{role.roleName}</option>
        )
    }

    const handleClick = (event) => {
        if (!isOpen && event.target.className !== 'account-request-action-select') {
            setOpenRequest(request.requestId);
        }
    }

    const showAgency = () => {
        return request !== undefined && (request.userRole === 'ROLE_AGUSER' || request.userRole === 'ROLE_AGADMIN');
    }

    return (
        <div className={"account-request" + (isOpen ? ' active' : '')} onClick={handleClick}>
            <div className="account-request-header">
                <div className="account-request-name">{getUserName()}</div>
                <select className="account-request-action-select" name="action" onChange={handleChange}>
                    <option>Do nothing</option>
                    <option>Approve</option>
                    <option>Reject</option>
                </select>
            </div>
            <div className="account-request-email">{getUserEmail()}</div>
            <Collapse isOpen={!isOpen}>
                <div className="account-request-preview">
                    <div className="account-request-preview-role">Role: <span className="highlight-primary">{getRoleShort()}</span></div>
                    <div className="account-request-preview-agency" hidden={!showAgency()}>Agency: <span className="highlight-primary">{getAgency()}</span></div>
                </div>
            </Collapse>
            <Collapse isOpen={isOpen}>
                <div className="account-request-body">
                    <div className="account-request-org-wrapper">
                        <div className="account-request-org-role">{getOrgRole()}</div>
                        <div hidden={getOrganization() === null || getOrgRole() === null}>at</div>
                        <div className="account-request-organization">{getOrganization()}</div>
                    </div>
                    <div className="account-request-requested-sites">
                        <Label className='account-request-label' for="sites">Sites Requested:</Label>
                        <div className="account-request-requested-sites-body" name='sites'>{getSites()}</div>
                    </div>
                    <div className="account-request-sites-comments">
                        <Label className='account-request-label' for="comments">Intended Use:</Label>
                        <div className="account-request-sites-comments-body" name='comments'>{getComments()}</div>
                    </div>
                    <div className="account-request-role-wrapper">
                        <Label className='account-request-label' for="userRole">Role:</Label>
                        <select name="userRole" onChange={handleChange} value={getRole()}>
                            {mapRolesToOption()}
                        </select>
                        <div hidden={!showAgency()}>
                            <Label className='account-request-label' for="userAgency">Agency:</Label>
                            <select name="userAgency" onChange={handleChange} value={getAgency()}>
                                {mapAgenciesToOption()}
                            </select>
                        </div>
                    </div>
                    <div className="account-request-sites-wrapper">
                        <Label className='account-request-label' for="sites">Sites:</Label>
                        <Button className={'account-request-sites-input'} type="text" name="sites" onClick={() => openModal(requestId)}><span>{'Select sites...'}</span></Button>
                    </div>
                    <div className="account-request-sites-admincomments">
                        <Label className='account-request-label' for="adminComments">Admin Comments:</Label>
                        <Input className='account-request-sites-admincomments-input' type="textarea" name="adminComments" placeholder="Comments..." onChange={handleChange} value={getAdminComments()} />
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

function SiteSelectModal({ isOpen, toggle, requests, requestId, toggleSite, toggleGroup }) {
    return (
        <Modal id='site-select-modal' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Select Sites</ModalHeader>
            <ModalBody id='site-select-modal-body'>
                <SiteTree lineComponent={SiteSelectLine} requests={requests} requestId={requestId} toggleSite={toggleSite} toggleGroup={toggleGroup} />
            </ModalBody>
            <ModalFooter>
                <Button className="submit-button" onClick={toggle}>Confirm</Button>
            </ModalFooter>
        </Modal>
    )
}

function SiteSelectLine({ requests, requestId, node, toggleSite, toggleGroup, getSubTree }) {

    const isChecked = () => {
        return requests[requestId].sites.has(node.nodeId);
    }

    const onClick = (event) => {
        if (node.leaf) {
            toggleSite(requestId, node.nodeId);
        } else {
            toggleGroup(requestId, node.nodeId, getSubTree(node));
        }
    }

    return (
        <div className="site-select-line" onClick={onClick}>
            <div>{node.value}</div>
            <Input type='checkbox' checked={isChecked()} value={isChecked()} />
        </div>
    )
}

function EmptyRow() {
    return (
        <div id="account-requests-empty-row">No pending requests.</div>
    )
}

export default AccountRequests;