import React from "react";
import { Input } from "reactstrap";

const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'x', 'Tab', 'Backspace', 'ArrowLeft', 'ArrowRight'];

function TelephoneInput({ value, onChange, ...props }) {
    const filteredProps = () => {
        let filteredProps = { ...props };
        delete filteredProps['value'];
        delete filteredProps['onChange'];
        return filteredProps;
    }

    const getFormattedValue = () => {
        if (!value) return '';
        let extension = '';
        let countryCode = '';
        let number = value;
        number = number.replace(/[\.\s()+-]/g, '');

        const hasExtension = number.match('x') !== null;
        if (hasExtension) {
            extension = number.substring(number.indexOf('x'));
            number = number.substring(0, number.indexOf('x'));
        }

        const hasCountryCode = number.length > 10;
        if (hasCountryCode) {
            countryCode = number.substring(0, number.length - 10);
            number = number.substring(number.length - 10);
            return `+${countryCode} (${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}${hasExtension ? ' ' + extension : ''}`;
        } else if (number.length > 7) {
            return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}${hasExtension ? ' ' + extension : ''}`;

        } else if (number.length > 3) {
            return `${number.substring(0, 3)}-${number.substring(3)}${hasExtension ? ' ' + extension : ''}`;
        } else {
            return `${number}${hasExtension ? ' ' + extension : ''}`;
        }
    }

    const handleChange = (event) => {
        if (onChange === undefined) return null;
        let extension = '';
        let countryCode = '';
        let number = event.target.value;
        number = number.replace(/[\.\s()+-]/g, '');

        const hasExtension = number.match('x') !== null;
        if (hasExtension) {
            extension = number.substring(number.indexOf('x'));
            if (extension.length > 5) {
                event.preventDefault();
                return;
            }
            number = number.substring(0, number.indexOf('x'));
        }

        const hasCountryCode = number.length > 10;
        if (hasCountryCode) {
            countryCode = number.substring(0, number.length - 10);
            number = number.substring(number.length - 10);
            number = `${countryCode}-${number.substring(0, 3)}-${number.substring(3, 6)}-${number.substring(6)}${hasExtension ? extension : ''}`;
        } else if (number.length > 7) {
            number = `${number.substring(0, 3)}-${number.substring(3, 6)}-${number.substring(6)}${hasExtension ? extension : ''}`;
        } else if (number.length > 3) {
            number = `${number.substring(0, 3)}-${number.substring(3)}${hasExtension ? extension : ''}`;
        } else {
            number = `${number}${hasExtension ? extension : ''}`;
        }

        event.target.value = number;
        onChange(event);
    }

    const handleKeyDown = (event) => {
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    }

    return (
        <Input type='text' value={getFormattedValue()} onChange={handleChange} onKeyDown={handleKeyDown} {...filteredProps()} />
    )
}

export default TelephoneInput;