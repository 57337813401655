import React, { useState } from "react";
import { Button, Input, InputGroup } from "reactstrap";

import '../styles/PasswordField.css';
import { default as eye } from '../resources/eye-alt-svgrepo-com.svg';
import { default as eyeslash } from '../resources/eye-slash-alt-svgrepo-com.svg';

function PasswordField({fieldName, title, handleChange, value, onKeyDown}) {
    const [passwordHidden, setPasswordHidden] = useState(true);
    const togglePasswordHidden = () => setPasswordHidden(!passwordHidden);

    return (
        <InputGroup className='password-field'>
            <Input id={"password-field-" + fieldName} name={fieldName} type={passwordHidden ? "password" : "text"} placeholder={title} onChange={handleChange} value={value} onKeyDown={onKeyDown}/>
            <Button id={"password-field-" + fieldName + "-button"} className='show-hidden-button' color="primary" onClick={togglePasswordHidden}><img src={passwordHidden ? eye : eyeslash} className="inline-svg" alt='Show Password' /></Button>
        </InputGroup>
    )
}

export default PasswordField;