import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import "../../styles/Admin.css";

import AccountRequests from "./AccountRequests";
import ContactsAdmin from "./ContactsAdmin";
import RenderControl from "./RenderControl";
import UserAdmin from "./UserAdmin";
import ChecklistAdmin from "./checklist/ChecklistAdmin";
import OtherAdmin from "./OtherAdmin";
import DocumentAdmin from "./DocumentAdmin";

const siteSpecificTabs = ["contact-admin"];

function Admin({ selectedSite }) {
    const [currentTab, setCurrentTab] = useState("account-requests");

    useEffect(() => {
        if (selectedSite === "all" && siteSpecificTabs.includes(currentTab)) {
            setCurrentTab("account-requests");
        }
    }, [selectedSite]);

    const renderCurrentTab = () => {
        switch (currentTab) {
            case "account-requests":
                return <AccountRequests />;
            case "render-control":
                return <RenderControl />;
            case "user-admin":
                return <UserAdmin />;
            case "contact-admin":
                return <ContactsAdmin selectedSite={selectedSite} />;
            case "checklist-admin":
                return <ChecklistAdmin />;
            case "documents-admin":
                return <DocumentAdmin selectedSite={selectedSite} />;
            case "other-admin":
                return <OtherAdmin />;
            default:
                return null;
        }
    };

    const changeTab = (event) => {
        setCurrentTab(event.target.getAttribute("tab-id"));
    };

    const tabDisabled = () => {
        return selectedSite === "all";
    };

    return (
        <div id="admin-page">
            <h2>Site Administration</h2>
            <Nav id="admin-tabs" tabs>
                <NavItem className="admin-tab" id="admin-tab-account-requests">
                    <NavLink
                        tab-id="account-requests"
                        active={currentTab === "account-requests"}
                        onClick={changeTab}
                    >
                        Account Requests
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="admin-tab-render-control">
                    <NavLink
                        tab-id="render-control"
                        active={currentTab === "render-control"}
                        onClick={changeTab}
                    >
                        Components
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="admin-tab-users">
                    <NavLink
                        tab-id="user-admin"
                        active={currentTab === "user-admin"}
                        onClick={changeTab}
                    >
                        Users
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="admin-tab-checklists">
                    <NavLink
                        tab-id="checklist-admin"
                        active={currentTab === "checklist-admin"}
                        onClick={changeTab}
                    >
                        Checklists
                    </NavLink>
                </NavItem>
                <NavItem
                    className={"admin-tab" + (tabDisabled() ? " disabled" : "")}
                    id="admin-tab-contacts"
                >
                    <NavLink
                        tab-id="contact-admin"
                        active={currentTab === "contact-admin"}
                        onClick={changeTab}
                        disabled={tabDisabled()}
                    >
                        Contacts
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="admin-tab-documents">
                    <NavLink
                        tab-id="documents-admin"
                        active={currentTab === "documents-admin"}
                        onClick={changeTab}
                    >
                        Documents
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="admin-tab-other">
                    <NavLink
                        tab-id="other-admin"
                        active={currentTab === "other-admin"}
                        onClick={changeTab}
                    >
                        Other
                    </NavLink>
                </NavItem>
            </Nav>
            <div id="admin-content">{renderCurrentTab()}</div>
        </div>
    );
}

export default Admin;
