import React, { useContext, useEffect, useState } from "react";
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupText,
    Label,
} from "reactstrap";

import { RestContext } from "../../App";
import { CHECKLIST_URL } from "../../serverConfig";
import {
    formatDatetime,
    formatDatetimeWithLocalTimezone,
} from "../../util/timeUtils";
import { checkMobile } from "../../util/globals";

function PreviousChecklists({ selectedSite }) {
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState();
    const [resultList, setResultList] = useState([]);
    const [selectedResults, setSelectedResults] = useState({});
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [filtersOpen, setFiltersOpen] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    const toggleFiltersOpen = () => setFiltersOpen(!filtersOpen);

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/types", (response) => {
            setTypeList(response.data);
            setSelectedType(response.data[0]);
        });
    }, []);

    useEffect(() => {
        if (selectedType && selectedSite) {
            const formData = new FormData();
            formData.append("type", selectedType);
            sendPostRequest(
                CHECKLIST_URL + "/results/" + selectedSite,
                formData,
                (response) => {
                    setResultList(response.data);
                }
            );
        }
    }, [selectedType, selectedSite]);

    useEffect(() => {
        console.log(resultList);
    }, [resultList]);

    useEffect(() => {
        console.log(selectedResults);
    }, [selectedResults]);

    useEffect(() => {
        console.log(minDate);
    }, [minDate]);

    useEffect(() => {
        console.log(maxDate);
    }, [maxDate]);

    const toggleSelected = (resultId) => {
        let newSelectedResults = { ...selectedResults };
        if (newSelectedResults[resultId]) {
            delete newSelectedResults[resultId];
        } else {
            newSelectedResults[resultId] = true;
        }
        setSelectedResults(newSelectedResults);
    };

    const mapTypesToOption = () => {
        if (!typeList) return null;
        return typeList.map((type, index) => (
            <option key={"checklist-create-modal-type-" + index}>{type}</option>
        ));
    };

    const mapResultsToCard = () => {
        if (!resultList) return null;
        if (selectedResults && Object.keys(selectedResults).length >= 2) {
            return resultList
                .filter((result) => result.resultId in selectedResults)
                .map((result, index) => (
                    <ChecklistResultCard
                        result={result}
                        isSelected={selectedResults[result.resultId]}
                        toggleSelected={toggleSelected}
                    />
                ));
        } else {
            return resultList
                .filter(
                    (result) =>
                        result.resultId in selectedResults ||
                        (!minDate && !maxDate) ||
                        ((minDate
                            ? result.dateCompleted.split("T")[0] >= minDate
                            : true) &&
                            (maxDate
                                ? result.dateCompleted.split("T")[0] <= maxDate
                                : true))
                )
                .map((result, index) => (
                    <ChecklistResultCard
                        result={result}
                        isSelected={selectedResults[result.resultId]}
                        toggleSelected={toggleSelected}
                    />
                ));
        }
    };

    const getResultsCounter = () => {
        if (!selectedResults) return null;
        const count = Object.keys(selectedResults).length;
        if (count === 1) {
            return (
                <div id="previous-checklists-selected-results-counter">
                    1 Selected Result
                </div>
            );
        } else {
            return (
                <div id="previous-checklists-selected-results-counter">
                    {`${count} Selected Results`}
                </div>
            );
        }
    };

    const handleTypeSelect = (event) => {
        setSelectedType(event.target.value);
    };

    const handleMinDate = (event) => {
        setMinDate(event.target.value);
    };

    const handleMaxDate = (event) => {
        setMaxDate(event.target.value);
    };

    const renderFilters = () => {
        if (checkMobile()) {
            return (
                <Dropdown id="previous-checklists-filters-dropdown" isOpen={filtersOpen} toggle={toggleFiltersOpen}>
                    <DropdownToggle id="previous-checklists-filters-button">
                        Filters
                    </DropdownToggle>
                    <DropdownMenu className="shadow-sm">
                        <InputGroup id="previous-checklists-date-range">
                            <InputGroupText>Dates</InputGroupText>
                            <Input
                                className="previous-checklists-date"
                                type="date"
                                onChange={handleMinDate}
                                max={maxDate ? maxDate : null}
                            />
                            <Input
                                className="previous-checklists-date"
                                type="date"
                                onChange={handleMaxDate}
                                min={minDate ? minDate : null}
                            />
                        </InputGroup>
                    </DropdownMenu>
                </Dropdown>
            );
        } else {
            return (
                <InputGroup id="previous-checklists-date-range">
                    <InputGroupText>Dates</InputGroupText>
                    <Input
                        className="previous-checklists-date"
                        type="date"
                        onChange={handleMinDate}
                        max={maxDate ? maxDate : null}
                    />
                    <Input
                        className="previous-checklists-date"
                        type="date"
                        onChange={handleMaxDate}
                        min={minDate ? minDate : null}
                    />
                </InputGroup>
            )
        }
    };

    return (
        <div id="previous-checklists">
            <div id="previous-checklists-type-select-wrapper">
                <Label>Type:</Label>
                <select value={selectedType} onChange={handleTypeSelect}>
                    {mapTypesToOption()}
                </select>
                {renderFilters()}
            </div>
            <div id="previous-checklists-results-container">
                {mapResultsToCard()}
            </div>
            {getResultsCounter()}
        </div>
    );
}

function ChecklistResultCard({ result, isSelected, toggleSelected }) {
    const handleToggle = () => {
        toggleSelected(result.resultId);
    };

    const mapDataToRow = () => {
        if (!result || !result.data) return null;
        return result.data.map((data, index) => (
            <tr key={"checklist-result-" + result.resultId + "-data-" + index}>
                <td className="index">{index + 1 + ")"}</td>
                <td className="question">{data.inputText}</td>
                <td className="answer">
                    {data.value}
                    <span className="unit">
                        {data.inputUnit ? data.inputUnit : ""}
                    </span>
                </td>
            </tr>
        ));
    };

    return (
        <div className="checklist-result-card">
            <div className="checklist-result-card-header">
                <div className="checklist-result-card-name">
                    {result.checklistName}
                </div>
                <Input
                    className="checklist-result-card-select-button"
                    type="checkbox"
                    checked={isSelected}
                    onClick={handleToggle}
                    readOnly
                />
            </div>
            <div className="checklist-result-card-subheader">
                {`${formatDatetimeWithLocalTimezone(result.dateCompleted)} by ${
                    result.completedBy
                }`}
            </div>
            <div className="checklist-result-card-content">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={2}>Question</th>
                            <th>Response</th>
                        </tr>
                    </thead>
                    <tbody>{mapDataToRow()}</tbody>
                </table>
            </div>
        </div>
    );
}

export default PreviousChecklists;
