import React, { useState } from 'react';
import { Button } from 'reactstrap';

import '../styles/MobileSiteView.css';
import '../styles/siteDetails.css';
import { default as markerFilled } from '../resources/marker-filled.svg';
import { default as filesFilled } from '../resources/files-svgrepo-com.svg';
import { default as inventory } from '../resources/inventory-svgrepo-com.svg';
import { default as utilIcons } from '../resources/utilIcons';

import SiteMap from '../components/SiteMap';
import DocumentView from './DocumentView';
import SiteInventory from './SiteInventory';
import SiteContacts from './SiteContacts';
import { colors } from '../styles/colors';

export default function MobileSiteView({site, user}) {
    const [activeTab, setActiveTab] = useState('map');

    const changeTab = (event => {
        setActiveTab(event.target.getAttribute('tab-id'));
    });

    const getTabContent = () => {
        switch (activeTab) {
            case 'map':
                return <SiteMap site={site} />
            case 'docs':
                return <DocumentView site={site} user={user} />
            case 'inv':
                return <SiteInventory site={site} />
            case 'contacts':
                return <SiteContacts selectedSite={site} />
            default:
                return null;
        }
    }

    const getSiteName = () => {
        if (site === undefined) return null;
        return site.name + ((site.name2 === undefined || site.name2 === null || site.name2 === '') ? '' : ' - ' + site.name2);
    }

    const getSiteAbbr = () => {
        if (site === undefined) return null;
        return site.aqmetAbbr;
    }

    return (
        <div id='mobile-site-view'>
            <div id='mobile-site-view-content-wrapper'>
                <span id='site-details-sitename'>{getSiteName()}</span>
                <span id='site-details-siteabbr'>{getSiteAbbr()}</span>

                <div id='mobile-site-view-content'>
                    {getTabContent()}
                </div>
            </div>
            <MobileSiteTabs activeTab={activeTab} changeTab={changeTab}/>
        </div>
    )
}

function MobileSiteTabs({changeTab, activeTab}) {
    return (
        <div id='mobile-site-tabs'>
            <Button className='mobile-tab' tab-id='map' onClick={changeTab} active={activeTab === 'map'} color='primary'>
                <div className='mobile-tab-label'>
                    <img src={markerFilled} alt="map" />
                    <span>Map</span>
                </div>
            </Button>
            <Button className='mobile-tab' tab-id='docs' onClick={changeTab} active={activeTab === 'docs'} color='primary'>
                <div className='mobile-tab-label'>
                    <img src={filesFilled} alt="documents" />
                    <span>Documents</span>
                </div>
            </Button>
            <Button className='mobile-tab' tab-id='inv' onClick={changeTab} active={activeTab === 'inv'} color='primary'>
                <div className='mobile-tab-label'>
                    <img src={inventory} alt="inventory" />
                    <span>Inventory</span>
                </div>
            </Button>
            <Button className='mobile-tab' tab-id='contacts' onClick={changeTab} active={activeTab === 'contacts'} color='primary'>
                <div className='mobile-tab-label'>
                    {utilIcons.user(colors['ars-neutral-900'])}
                    <span>Contacts</span>
                </div>
            </Button>
        </div>
    )
}