import React, { useContext, useEffect, useState } from "react";

import { DASHBOARD_URL, corsConfig } from "../../serverConfig";
import { RestContext } from "../../App";
import { Spinner } from "reactstrap";

function Links({selectedSite}) {
    const [airNowLink, setAirNowLink] = useState();
    const [purpleAirLink, setPurpleAirLink] = useState();
    const [loading, setLoading] = useState(false);

    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite !== undefined) {
            if (selectedSite === 'all') {
                setAirNowLink();
                setPurpleAirLink();
            } else {
                setLoading(true);
                sendGetRequest(DASHBOARD_URL + '/links/' + selectedSite, (response) => {
                    setAirNowLink(response.data.airnowlink);
                    setPurpleAirLink(response.data.purpleairlink);
                    setLoading(false);
                }, (error) => {
                    setLoading(false);
                });
            }
        }
    }, [selectedSite]);
    
    const getLoading = () => {
        if (loading) {
            return <LinksLoading />
        }
    }

    return (
        <div id='dashboard-links' className="dashboard-widget">
            {getLoading()}
            <div className="dashboard-widget-title">Links</div>
            <AirNowLink link={airNowLink} hidden={!airNowLink} />
            <PurpleAirLink link={purpleAirLink} hidden={!purpleAirLink} />
            <NoLinks hidden={!(!airNowLink && !purpleAirLink)} />
        </div>
    )
}

function AirNowLink({link, hidden}) {
    return (
        <a href={link} target="_blank" hidden={hidden}>
            <div className="dashboard-link">
                <div className="dashboard-link-text">AirNow</div>
                <img className="dashboard-link-icon" style={{ backgroundColor: "#0B5697" }} alt="Air Now Logo" src="https://www.airnow.gov/sites/default/files/2017-12/AirNow_Logo_White.svg" />
            </div>
        </a>
    )
}

function PurpleAirLink({link, hidden}) {
    return (
        <a href={link} target="_blank" hidden={hidden}>
            <div className="dashboard-link">
                <div className="dashboard-link-text">PurpleAir</div>
                <img className="dashboard-link-icon" alt="Purple Air Logo" src="https://global.discourse-cdn.com/business7/uploads/purpleair/original/1X/5da90a7ae1f7595ee7d831e5838525c23371f1f1.png" />
            </div>
        </a >
    )
}

function NoLinks({hidden}) {
    return (
        <div id='dashboard-links-no-links' className="dashboard-no-data" hidden={hidden}>
            No links found.
        </div>
    )
}

function LinksLoading() {
    return (
        <div id='links-loading' className="dashboard-widget-loading">
            <Spinner color="light" />
        </div>
    )
}

export default Links;