import React, { useContext, useEffect, useState } from "react";

import { default as utilIcons } from "../../resources/utilIcons";

import { RestContext } from "../../App";
import { CHECKLIST_URL } from "../../serverConfig";
import { colors } from "../../styles/colors";
import { Button, Spinner } from "reactstrap";
import { formatDatetimeWithLocalTimezone } from "../../util/timeUtils";

function DashboardChecks({ selectedSite }) {
    const [resultList, setResultList] = useState([]);
    const [timestamp, setTimestamp] = useState();
    const [loading, setLoading] = useState(false);

    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite) {
            loadData();
        }
    }, [selectedSite]);

    const loadData = () => {
        setLoading(true);
        sendGetRequest(
            CHECKLIST_URL + "/results/recent/" + selectedSite,
            (response) => {
                setResultList(response.data);
                setTimestamp(formatDatetimeWithLocalTimezone(new Date()));
                setLoading(false);
            }, (error) => {
                setLoading(false);
            }
        );
    };

    const mapResultsToCard = () => {
        if (!resultList) return null;
        return resultList.map((result, index) => (
            <DashboardChecksResultCard
                key={"dashboard-checks-result-" + index}
                result={result}
            />
        ));
    };

    const checkNoData = () => !resultList || resultList.length === 0;

    const getLoading = () => {
        if (loading) {
            return <DashboardChecksLoading />;
        }
    };

    return (
        <div id="dashboard-checks" className="dashboard-widget">
            {getLoading()}
            <div id="dashboard-checks-header">
                <div className="dashboard-widget-title">Checklists</div>
                <div id="dashboard-checks-timestamp" hidden={checkNoData()}>
                    {timestamp}
                </div>
                <Button
                    id="dashboard-checks-refresh"
                    className="dashboard-widget-refresh"
                    onClick={loadData}
                >
                    {utilIcons["refresh"](colors["ars-neutral-400"])}
                </Button>
            </div>
            <div id="dashboard-checks-results-container">
                <NoData hidden={resultList.length > 0} />
                {mapResultsToCard()}
            </div>
        </div>
    );
}

function DashboardChecksResultCard({ result }) {
    const mapDataToRow = () => {
        if (!result || !result.data) return null;
        return result.data.map((data, index) => (
            <tr
                key={
                    "dashboard-checks-result-" +
                    result.resultId +
                    "-data-" +
                    index
                }
            >
                <td className="index">{index + 1 + ")"}</td>
                <td className="question">{data.inputText}</td>
                <td className="answer">
                    {data.value}
                    <span className="unit">
                        {data.inputUnit ? data.inputUnit : ""}
                    </span>
                </td>
            </tr>
        ));
    };

    return (
        <div className="dashboard-checks-result-card">
            <div className="dashboard-checks-result-card-header">
                <div className="dashboard-checks-result-card-name">
                    {result.checklistName}
                </div>
            </div>
            <div className="dashboard-checks-result-card-subheader">
                {`${formatDatetimeWithLocalTimezone(result.dateCompleted)} by ${
                    result.completedBy
                }`}
            </div>
            <div className="dashboard-checks-result-card-content">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={2}>Question</th>
                            <th>Response</th>
                        </tr>
                    </thead>
                    <tbody>{mapDataToRow()}</tbody>
                </table>
            </div>
        </div>
    );
}

function NoData({ hidden }) {
    return (
        <div
            id="dashboard-checks-no-data"
            className="dashboard-no-data"
            hidden={hidden}
        >
            No data available.
        </div>
    );
}

function DashboardChecksLoading() {
    return (
        <div id="dashboard-checks-loading" className="dashboard-widget-loading">
            <Spinner color="light" />
        </div>
    );
}

export default DashboardChecks;
