import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Collapse, Input, Label } from "reactstrap";
import { DASHBOARD_URL } from "../../serverConfig";
import { RestContext } from "../../App";
import { checkMobile } from "../../util/globals";

function Feedback(props) {
    if (checkMobile()) {
        return (
            <FeedbackMobile {...props} />
        )
    } else {
        return (
            <FeedbackDesktop {...props} />
        )
    }
}

function FeedbackDesktop() {
    const [formData, setFormData] = useState({ email: "", feedback: "" });
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const ref = useRef(null);

    const { sendPostRequest } = useContext(RestContext);

    const toggleFeedbackOpen = () => setFeedbackOpen(!feedbackOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && feedbackOpen) {
                if (!ref.current.contains(event.target)) {
                    toggleFeedbackOpen();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [ref, feedbackOpen, toggleFeedbackOpen]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const canSubmit = () => {
        return (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) && formData.feedback !== "";
    }

    const handleSubmit = () => {
        const payload = new FormData();
        payload.append("email", formData.email);
        payload.append("feedback", formData.feedback);
        sendPostRequest(DASHBOARD_URL + '/feedback', payload, (response) => {
            setFormData({ email: "", feedback: "" });
        });
    }

    return (
        <div id='dashboard-feedback-desktop-wrapper'>
            <Button id='dashboard-feedback-desktop-toggle' color="primary" onClick={toggleFeedbackOpen}>Feedback?</Button>
            <Collapse id='dashboard-feedback' isOpen={feedbackOpen}>
                <div id='dashboard-feedback-wrapper' className="dashboard-widget" ref={ref}>
                    <Button className="btn-close" aria-label="Close" onClick={toggleFeedbackOpen} />
                    <div className="dashboard-widget-title">Feedback</div>
                    <p>Please report any bugs, suggestions, or other comments you have about DataView.</p>
                    <Label for='email'>Contact Address</Label>
                    <Input type='email' name='email' placeholder="Contact address" value={formData.email} onChange={handleChange} />
                    <Label for='feedback'>Feedback</Label>
                    <div id='dashboard-feedback-detail-wrapper'>
                        <Input id='dashboard-feedback-detail' type="textarea" name='feedback' placeholder="Questions, bugs, suggestions, etc..." value={formData.feedback} onChange={handleChange} rows="3" maxLength={500} />
                        <div id='dashboard-feedback-detail-counter'>{formData.feedback.length + ' / 500'}</div>
                    </div>
                    <Button id='dashboard-feedback-submit' className='submit-button' disabled={!canSubmit()} onClick={handleSubmit}>Submit</Button>
                </div>
            </Collapse>
        </div>
    )
}

function FeedbackMobile() {
    const [formData, setFormData] = useState({ email: "", feedback: "" });

    const { sendPostRequest } = useContext(RestContext);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const canSubmit = () => {
        return (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) && formData.feedback !== "";
    }

    const handleSubmit = () => {
        const payload = new FormData();
        payload.append("email", formData.email);
        payload.append("feedback", formData.feedback);
        sendPostRequest(DASHBOARD_URL + '/feedback', payload, (response) => {
            setFormData({ email: "", feedback: "" });
        });
    }

    return (
        <div id='dashboard-feedback' className="dashboard-widget">
            <p>Please report any bugs, suggestions, or other comments you have about DataView.</p>
            <Label for='email'>Contact Address</Label>
            <Input type='email' name='email' placeholder="Contact address (email)" value={formData.email} onChange={handleChange} />
            <Label for='feedback'>Feedback</Label>
            <div id='dashboard-feedback-detail-wrapper'>
                <Input id='dashboard-feedback-detail' type="textarea" name='feedback' placeholder="Questions, bugs, suggestions, etc..." value={formData.feedback} onChange={handleChange} rows="8" maxLength={500} />
                <div id='dashboard-feedback-detail-counter'>{formData.feedback.length + ' / 500'}</div>
            </div>
            <Button id='dashboard-feedback-submit' className='submit-button' disabled={!canSubmit()} onClick={handleSubmit}>Submit</Button>
        </div>
    )
}

export default Feedback;