import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import "../../styles/Dashboard.css";

import CurrentData from "./CurrentData";
import Links from "./Links";
import Feedback from "./Feedback";
import { Button, Input } from "reactstrap";
import { checkMobile } from "../../util/globals";
import { default as utilIcons } from "../../resources/utilIcons";
import Visuals from "./Visuals";
import DashboardContacts from "./DashboardContacts";
import { RenderContext, RestContext } from "../../App";
import { colors } from "../../styles/colors";
import { DASHBOARD_URL } from "../../serverConfig";
import DashboardChecks from "./DashboardChecks";

export const DashboardContext = createContext(null);

const widgets = [
    {
        name: "Current Data",
        component: CurrentData,
        size: "large",
    },
    
    {
        name: "Visuals",
        component: Visuals,
        size: "large",
    },
    
    {
        name: "Checklists",
        component: DashboardChecks,
        size: "large"
    },

    {
        name: "Contacts",
        component: DashboardContacts,
        size: "small",
    },
    {
        name: "Links",
        component: Links,
        size: "small",
    },
    {
        name: "Feedback",
        component: Feedback,
        size: "small",
        all: true,
    },
];

function Dashboard({ selectedSite, siteDetails, ...props }) {
    const [currentWidget, setCurrentWidget] = useState(0);
    const [widgetWidth, setWidgetWidth] = useState();
    const [metric, setMetric] = useState(false);
    const ref = useRef(null);
    const [aqiRanges, setAqiRanges] = useState({});
    const [aqiColors, setAqiColors] = useState([]);

    const { sendGetRequest } = useContext(RestContext);

    const toggleMetric = () => setMetric(!metric);

    useEffect(() => {
        sendGetRequest(DASHBOARD_URL + "/aqiRanges", (response) => {
            setAqiRanges(response.data.ranges);
            setAqiColors(response.data.colors);
        });
    }, []);

    useEffect(() => {
        if (ref.current) {
            setWidgetWidth(window.innerWidth);
        }
    }, [ref.current]);

    useEffect(() => {
        setCurrentWidget(0);
    }, [widgetWidth]);

    const doScrollLeft = () => {
        if (ref.current) {
            const currScroll = ref.current.scrollLeft;
            const index = Math.round(currScroll / widgetWidth);
            ref.current.scrollTo({
                left: Math.max(index - 1, 0) * widgetWidth,
                behavior: "smooth",
            });
        }
    };

    const doScrollRight = () => {
        if (ref.current) {
            const currScroll = ref.current.scrollLeft;
            const index = Math.round(currScroll / widgetWidth);
            ref.current.scrollTo({
                left: Math.min(index + 1, widgets.length - 1) * widgetWidth,
                behavior: "smooth",
            });
        }
    };

    const getWidgets = () => {
        if (selectedSite === "all") {
            return widgets.map((widget, index) => {
                if (widget.all) {
                    return (
                        <widget.component
                            key={"dashboard-widget-" + index}
                            selectedSite={selectedSite}
                            metric={metric}
                            {...getRequiredProps(widget)}
                        />
                    );
                } else {
                    return null;
                }
            });
        } else {
            return widgets.map((widget, index) => {
                return (
                    <widget.component
                        key={"dashboard-widget-" + index}
                        selectedSite={selectedSite}
                        metric={metric}
                        {...getRequiredProps(widget)}
                    />
                );
            });
        }
    };

    const getLargeWidgets = () => {
        if (selectedSite === "all") {
            return widgets.map((widget, index) => {
                if (widget.all && widget.size === "large") {
                    return (
                        <widget.component
                            key={"dashboard-widget-" + index}
                            selectedSite={selectedSite}
                            metric={metric}
                            {...getRequiredProps(widget)}
                        />
                    );
                } else {
                    return null;
                }
            });
        } else {
            return widgets.map((widget, index) => {
                if (widget.size === "large") {
                    return (
                        <widget.component
                            key={"dashboard-widget-" + index}
                            selectedSite={selectedSite}
                            metric={metric}
                            {...getRequiredProps(widget)}
                        />
                    );
                } else {
                    return null;
                }
            });
        }
    };

    const getSmallWidgets = () => {
        if (selectedSite === "all") {
            return widgets.map((widget, index) => {
                if (
                    widget.all &&
                    widget.size === "small" &&
                    widget.name !== "Feedback"
                ) {
                    return (
                        <widget.component
                            key={"dashboard-widget-" + index}
                            selectedSite={selectedSite}
                            metric={metric}
                            {...getRequiredProps(widget)}
                        />
                    );
                } else {
                    return null;
                }
            });
        } else {
            return widgets.map((widget, index) => {
                if (widget.size === "small" && widget.name !== "Feedback") {
                    return (
                        <widget.component
                            key={"dashboard-widget-" + index}
                            selectedSite={selectedSite}
                            metric={metric}
                            {...getRequiredProps(widget)}
                        />
                    );
                } else {
                    return null;
                }
            });
        }
    };

    const getFeedbackWidget = () => {
        const widget = widgets.find((widget) => widget.name === "Feedback");
        return <widget.component />;
    };

    const getRequiredProps = (widget) => {
        const obj = {};
        if (widget.requiredProps) {
            for (let prop of widget.requiredProps) {
                obj[prop] = props[prop];
            }
        }
        return obj;
    };

    const handleScroll = (event) => {
        const currScroll = event.target.scrollLeft;
        const currWidget = Math.round(currScroll / widgetWidth);
        if (currWidget !== currentWidget) {
            setCurrentWidget(currWidget);
        }
    };

    const handleLeft = (event) => {
        if (currentWidget > 0) {
            doScrollLeft();
        }
    };

    const handleRight = (event) => {
        if (currentWidget < widgets.length - 1) {
            doScrollRight();
        }
    };

    const getAqiRange = (aqiParameter) => {
        if (!aqiRanges || !aqiRanges[aqiParameter]) return [];
        return aqiRanges[aqiParameter];
    };

    const desktopDashboard = () => {
        const largeWidgets = getLargeWidgets();
        return (
            <div id="dashboard">
                <DashboardContext.Provider value={{ getAqiRange, aqiColors, siteDetails }}>
                    <div id="dashboard-main">
                        <div id="dashboard-header">
                            <h2>Dashboard</h2>
                            <div
                                id="dashboard-metric-checkbox-wrapper"
                                onClick={toggleMetric}
                                hidden={selectedSite === "all"}
                            >
                                <span>Metric</span>
                                <Input
                                    id="dashboard-metric-checkbox"
                                    type="checkbox"
                                    checked={metric}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div id="dashboard-large-widgets-wrapper">
                            <NoLargeWidgets
                                hidden={largeWidgets.some((widget) => widget)}
                            />
                            {largeWidgets}
                        </div>
                        <TestRenderControl />
                    </div>
                    <div id="dashboard-side">
                        <div id="dashboard-small-widgets-wrapper">
                            {getSmallWidgets()}
                        </div>
                        {getFeedbackWidget()}
                    </div>
                </DashboardContext.Provider>
            </div>
        );
    };

    const mobileDashboard = () => {
        return (
            <div id="dashboard">
                <DashboardContext.Provider value={{ getAqiRange, aqiColors, siteDetails }}>
                    <div id="dashboard-header">
                        <h2>Dashboard</h2>
                        <Button
                            id="dashboard-metric-button"
                            className={metric ? "metric-on" : "metric-off"}
                            onClick={toggleMetric}
                            hidden={selectedSite === "all"}
                        >
                            Metric
                        </Button>
                        {renderSwitch()}
                    </div>
                    {getMobileContent()}
                </DashboardContext.Provider>
            </div>
        );
    };

    const getMobileContent = () => {
        if (selectedSite === undefined) return null;
        return (
            <div
                id="dashboard-widgets-wrapper"
                ref={ref}
                onScroll={handleScroll}
            >
                {getWidgets()}
            </div>
        );
    };

    const renderSwitch = () => {
        if (
            checkMobile() &&
            selectedSite !== undefined &&
            selectedSite !== "all"
        ) {
            return (
                <MobileWidgetSwitch
                    currentWidget={currentWidget}
                    handleLeft={handleLeft}
                    handleRight={handleRight}
                />
            );
        } else {
            return null;
        }
    };

    return checkMobile() ? mobileDashboard() : desktopDashboard();
}

function MobileWidgetSwitch({ currentWidget, handleLeft, handleRight }) {
    const getWidgetName = () => {
        if (currentWidget === undefined || widgets[currentWidget] === undefined)
            return null;
        return widgets[currentWidget].name;
    };

    const getMaxWidgetLength = () => {
        const maxLength =
            Math.max(...widgets.map((widget) => widget.name.length)) - 1;
        return maxLength + "ch";
    };

    const leftDisabled = () => {
        return currentWidget <= 0;
    };

    const rightDisabled = () => {
        return currentWidget >= widgets.length - 1;
    };

    return (
        <div id="dashboard-mobile-widget-switch">
            <Button
                className="dashboard-mobile-widget-switch-button"
                onClick={handleLeft}
                disabled={leftDisabled()}
            >
                {utilIcons["caret"](leftDisabled() ? "BEC0C2" : "#66686A", {
                    rotate: "90deg",
                })}
            </Button>
            <div
                id="dashboard-mobile-current-widget"
                style={{ width: getMaxWidgetLength() }}
            >
                {getWidgetName()}
            </div>
            <Button
                className="dashboard-mobile-widget-switch-button"
                onClick={handleRight}
                disabled={rightDisabled()}
            >
                {utilIcons["caret"](rightDisabled() ? "BEC0C2" : "#66686A", {
                    rotate: "270deg",
                })}
            </Button>
        </div>
    );
}

function NoLargeWidgets({ hidden }) {
    return (
        <div
            id="dashboard-no-large-widgets"
            className="dashboard-no-data"
            hidden={hidden}
        >
            {utilIcons.arsLogoText(colors["ars-neutral-700"])}
            <div>No widgets available. Please select a different site.</div>
        </div>
    );
}

function TestRenderControl() {
    const { renderControlledComponent } = useContext(RenderContext);

    return renderControlledComponent(
        <div id="render-control-test">Test Render Control</div>
    );
}

export default Dashboard;
