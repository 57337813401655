import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
    UncontrolledDropdown,
} from "reactstrap";

import { default as utilIcons } from "../../resources/utilIcons";

import { ModalContext, RestContext } from "../../App";
import { DOC_URL, SITE_URL } from "../../serverConfig";
import { colors } from "../../styles/colors";
import { formatDate, formatDatetime } from "../../util/timeUtils";
import SearchableSelect from "../../components/SearchableSelect";
import { FileUploader } from "react-drag-drop-files";

function DocumentAdmin({ selectedSite }) {
    const [siteList, setSiteList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [activeDocs, setActiveDocs] = useState({});
    const [selectedDocument, setSelectedDocument] = useState();
    const [documentToDelete, setDocumentToDelete] = useState();
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);
    const { openModal, setOpenModal } = useContext(ModalContext);

    const toggleShowInactive = () => {
        if (!changed) setShowInactive(!showInactive);
    };
    const toggleUploadModal = () => {
        if (openModal === "documents-admin-upload-modal") {
            setOpenModal();
        } else {
            setOpenModal("documents-admin-upload-modal");
        }
    };
    const toggleEditModal = () => {
        if (openModal === "documents-admin-edit-modal") {
            setOpenModal();
        } else {
            setOpenModal("documents-admin-edit-modal");
        }
    };
    const toggleDeleteModal = () => {
        if (openModal === "documents-admin-delete-modal") {
            setOpenModal();
        } else {
            setOpenModal("documents-admin-delete-modal");
        }
    };

    useEffect(() => {
        sendGetRequest(SITE_URL + "/list", (response) => {
            const newSiteList = response.data.map((site) => ({
                siteId: site.siteId,
                siteText: `${site.siteAbbr} - ${site.fullName}`,
                siteGroup: site.agencyName,
            }));
            setSiteList(newSiteList);
        });
    }, []);

    useEffect(() => {
        if (selectedSite) {
            loadDocList();
        }
    }, [selectedSite]);

    useEffect(() => {
        if (documentList) {
            let newActiveDocs = {};
            for (let document of documentList) {
                newActiveDocs[document.docId] = document.active ? true : false;
            }
            setActiveDocs(newActiveDocs);
        }
    }, [documentList]);

    const loadDocList = () => {
        sendGetRequest(DOC_URL + "/admin/" + selectedSite, (response) => {
            setDocumentList(response.data);
        });
    };

    const getActive = (docId) => {
        if (!activeDocs || !activeDocs[docId]) return false;
        return true;
    };

    const toggleActive = (docId) => {
        if (!activeDocs) return;
        setActiveDocs((values) => ({ ...values, [docId]: !activeDocs[docId] }));
        setChanged(true);
        setShowInactive(true);
    };

    const handleDocumentClick = (document) => {
        setSelectedDocument(document);
    };

    const mapDocumentListToRow = () => {
        if (!documentList) return null;
        const docList = showInactive
            ? documentList
            : documentList.filter((document) => document.active);
        return docList.map((document, index) => (
            <tr
                key={"document-admin-row-" + index}
                className={
                    selectedDocument &&
                    selectedDocument.docId === document.docId
                        ? "selected"
                        : ""
                }
                onClick={() => handleDocumentClick(document)}
            >
                <td className="active">
                    <Input
                        type="checkbox"
                        checked={getActive(document.docId)}
                        onClick={() => toggleActive(document.docId)}
                        readOnly
                    />
                </td>
                <td>{document.docName}</td>
                <td>{document.docTypeDesc}</td>
                <td>{formatDate(document.docDate)}</td>
                <td className="buttons">
                    <a href={DOC_URL + "/download/" + document.docId}>
                        <Button className="document-admin-row-button">
                            {utilIcons.download(colors["ars-blue-300"])}
                        </Button>
                    </a>
                    <UncontrolledDropdown>
                        <DropdownToggle className="document-admin-row-button">
                            {utilIcons.vDots(colors["ars-neutral-400"])}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={toggleEditModal}>
                                Edit Document
                            </DropdownItem>
                            <DropdownItem
                                style={{ color: colors["ars-error"] }}
                                onClick={toggleDeleteModal}
                            >
                                Delete Document
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            </tr>
        ));
    };

    const deleteDocument = (docId) => {
        sendPostRequest(
            DOC_URL + "/admin/delete/" + docId,
            null,
            (response) => {
                loadDocList();
                setOpenModal();
            }
        );
    };

    const handleSubmit = () => {
        if (changed && !waiting) {
            setWaiting(true);
            sendPostRequest(
                DOC_URL + "/admin/activate/" + selectedSite,
                activeDocs,
                (response) => {
                    setWaiting(false);
                    setChanged(false);
                    loadDocList();
                },
                (error) => {
                    setWaiting(false);
                }
            );
        }
    };

    return (
        <div id="document-admin">
            <div id="document-admin-left">
                <div id="document-admin-main-wrapper">
                    <div id="document-admin-main-buttons-wrapper">
                        <div id="document-admin-inactive-wrapper">
                            <Label>Show Inactive Documents</Label>
                            <Input
                                type="checkbox"
                                checked={showInactive}
                                onClick={toggleShowInactive}
                                disabled={changed}
                                readOnly
                            />
                        </div>
                        <Button
                            id="document-admin-upload-button"
                            onClick={toggleUploadModal}
                        >
                            {"Upload New Document"}
                            {utilIcons.upload(colors["ars-neutral-900"])}
                        </Button>
                    </div>
                    <div id="document-admin-table-wrapper">
                        <div id="document-admin-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="active">Active</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th className="buttons" />
                                    </tr>
                                </thead>
                                <tbody>{mapDocumentListToRow()}</tbody>
                            </table>
                        </div>
                    </div>
                    <div id="document-admin-submit-wrapper">
                        <Button
                            id="document-admin-submit"
                            className="submit-button"
                            disabled={!changed}
                            onClick={handleSubmit}
                        >
                            {waiting ? <Spinner size="sm" /> : "Save"}
                        </Button>
                    </div>
                </div>
                <UploadModal
                    siteId={selectedSite}
                    isOpen={openModal === "documents-admin-upload-modal"}
                    toggle={toggleUploadModal}
                    onUpload={loadDocList}
                />
                <DocumentEditModal
                    isOpen={openModal === "documents-admin-edit-modal"}
                    toggle={toggleEditModal}
                    document={selectedDocument}
                    selectedSite={selectedSite}
                    onSubmit={loadDocList}
                />
                <DocumentDeleteModal
                    isOpen={openModal === "documents-admin-delete-modal"}
                    toggle={toggleDeleteModal}
                    document={selectedDocument}
                    deleteDocument={deleteDocument}
                />
            </div>
            <div id="document-admin-right">
                <h3>Preview</h3>
                <div id="document-admin-preview">
                    <DocumentAdminPreview doc={selectedDocument} />
                </div>
            </div>
        </div>
    );
}

function UploadModal({ siteId, isOpen, toggle, onUpload }) {
    const [docs, setDocs] = useState([]);
    const [docTypes, setDocTypes] = useState({});
    const [docDates, setDocDates] = useState({});

    const { sendPostRequest } = useContext(RestContext);

    const getDefaultDate = () => {
        const date = new Date();
        return date.toJSON().split("T")[0];
    };

    const handleChange = (files) => {
        let newDocs = [...docs];
        let newDocTypes = { ...docTypes };
        let newDocDates = { ...docDates };
        for (let file of files) {
            if (!newDocs.some((doc) => doc.name === file.name)) {
                newDocs.push(file);
                newDocTypes[file.name] = "MAINT_CAL_RPT";
                newDocDates[file.name] = getDefaultDate();
            }
        }
        setDocs(newDocs);
        setDocTypes(newDocTypes);
        setDocDates(newDocDates);
    };

    const removeDoc = (doc) => {
        for (let index in docs) {
            if (docs[index].name === doc.name) {
                let newDocs = [...docs];
                newDocs.splice(index, 1);
                setDocs(newDocs);
                let newDocTypes = { ...docTypes };
                delete newDocTypes[doc.name];
                setDocTypes(newDocTypes);
                let newDocDates = { ...docDates };
                delete newDocDates[doc.name];
                setDocDates(newDocDates);
                return;
            }
        }
    };

    const handleType = (event, doc) => {
        setDocTypes((values) => ({
            ...values,
            [doc.name]: event.target.value,
        }));
    };

    const handleDate = (event, doc) => {
        setDocDates((values) => ({
            ...values,
            [doc.name]: event.target.value,
        }));
    };

    const mapDocsToRow = () => {
        if (docs === undefined) return null;
        return docs.map((doc, index) => (
            <tr
                className="document-upload-row"
                key={"document-upload-row-" + index}
            >
                <td className="doc-name">
                    <Button
                        className="btn-close"
                        onClick={() => removeDoc(doc)}
                    />
                    <span>{doc.name}</span>
                </td>
                <td className="doc-type">
                    <select onChange={(event) => handleType(event, doc)}>
                        <option value={"MAINT_CAL_RPT"}>
                            Maintenance and Calibration Report
                        </option>
                        <option value={"QAPP"}>
                            Quality Assurance Project Plan
                        </option>
                        <option value={"CHECKLIST"}>
                            Checklist Instructions - Weekly Site Visits
                        </option>
                        <option value={"SOP"}>
                            Standard Operating Procedure
                        </option>
                    </select>
                </td>
                <td className="doc-date">
                    <Input
                        type="date"
                        value={docDates[doc.name]}
                        onChange={(event) => handleDate(event, doc)}
                    />
                </td>
            </tr>
        ));
    };

    const handleSubmit = () => {
        const fileTypes = Object.keys(docTypes).map(
            (docName) => docName + "=" + docTypes[docName]
        );
        const fileTypeString = fileTypes.join(";");
        const fileDates = Object.keys(docDates).map(
            (docName) => docName + "=" + docDates[docName] + " 06:00:00"
        );
        const fileDateString = fileDates.join(";");
        const formData = new FormData();
        for (let doc of docs) {
            formData.append("files", doc);
        }
        formData.append("fileTypes", fileTypeString);
        formData.append("fileDates", fileDateString);
        sendPostRequest(DOC_URL + "/upload/" + siteId, formData, (response) => {
            setDocs(new Array());
            setDocTypes({});
            setDocDates({});
            onUpload();
            toggle();
        });
    };

    const handleClosed = () => {
        setDocs([]);
    };

    return (
        <Modal
            id="document-upload-modal"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={handleClosed}
        >
            <ModalHeader toggle={toggle}>Upload Site Documents</ModalHeader>
            <ModalBody>
                <div id="document-upload-table-wrapper" hidden={!docs || docs.length === 0}>
                    <div id="document-upload-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>{mapDocsToRow()}</tbody>
                        </table>
                    </div>
                </div>

                <FileUploader
                    handleChange={handleChange}
                    classes="file-upload"
                    name="file"
                    types={[
                        "PDF",
                        "PNG",
                        "JPG",
                        "JPEG",
                        "BMP",
                        "GIF",
                        "XLSX",
                        "HEIC",
                        "HEIF",
                    ]}
                    multiple
                />
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={docs.length === 0}
                >
                    Submit
                </Button>
            </ModalBody>
        </Modal>
    );
}

function DocumentEditModal({
    isOpen,
    toggle,
    document,
    selectedSite,
    onSubmit,
}) {
    const [docToSubmit, setDocToSubmit] = useState({});
    const [changed, setChanged] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const { sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        if (document) {
            setDocToSubmit({ ...document });
        }
    }, [document]);

    const getDocName = () => {
        if (!docToSubmit || !docToSubmit.docName) return "";
        return docToSubmit.docName;
    };

    const getDocType = () => {
        if (!docToSubmit || !docToSubmit.docTypeCode) return null;
        return docToSubmit.docTypeCode;
    };

    const getDocDate = () => {
        if (!docToSubmit || !docToSubmit.docDate) return null;
        return docToSubmit.docDate.split("T")[0];
    };

    const getActive = () => {
        if (!docToSubmit || !docToSubmit.active) return false;
        return true;
    };

    const handleName = (event) => {
        setChanged(true);
        const newDoc = { ...docToSubmit };
        newDoc.docName = event.target.value;
        setDocToSubmit(newDoc);
    };

    const handleType = (event) => {
        setChanged(true);
        const newDoc = { ...docToSubmit };
        newDoc.docTypeCode = event.target.value;
        setDocToSubmit(newDoc);
    };

    const handleDate = (event) => {
        setChanged(true);
        const newDateString = event.target.value + "T06:00:00.000+00:00";
        const newDoc = { ...docToSubmit };
        newDoc.docDate = newDateString;
        setDocToSubmit(newDoc);
    };

    const handleActive = () => {
        setChanged(true);
        const newDoc = { ...docToSubmit };
        if (!docToSubmit || !docToSubmit.active) {
            newDoc.active = 1;
        } else {
            newDoc.active = 0;
        }
        setDocToSubmit(newDoc);
    };

    const handleSubmit = () => {
        setWaiting(true);
        sendPostRequest(
            DOC_URL + "/admin/update/" + selectedSite,
            docToSubmit,
            (response) => {
                setWaiting(false);
                setChanged(false);
                onSubmit();
                toggle();
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <Modal id="document-edit-modal" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Document</ModalHeader>
            <ModalBody>
                <div class="document-edit-modal-field-wrapper">
                    <Label>Document Name:</Label>
                    <Input value={getDocName()} onChange={handleName} />
                </div>
                <div class="document-edit-modal-field-wrapper">
                    <Label>Document Type:</Label>
                    <select value={getDocType()} onChange={handleType}>
                        <option value={"MAINT_CAL_RPT"}>
                            Maintenance and Calibration Report
                        </option>
                        <option value={"QAPP"}>
                            Quality Assurance Project Plan
                        </option>
                        <option value={"CHECKLIST"}>
                            Checklist Instructions - Weekly Site Visits
                        </option>
                        <option value={"SOP"}>
                            Standard Operating Procedure
                        </option>
                    </select>
                </div>
                <div class="document-edit-modal-field-wrapper">
                    <Label>Date:</Label>
                    <Input
                        type="date"
                        value={getDocDate()}
                        onChange={handleDate}
                    />
                </div>
                <div class="document-edit-modal-field-wrapper">
                    <Label>Active:</Label>
                    <Input
                        type="checkbox"
                        checked={getActive()}
                        onChange={handleActive}
                        readOnly
                    />
                </div>
                <Button
                    className="submit-button"
                    disabled={!changed}
                    onClick={handleSubmit}
                >
                    {waiting ? <Spinner size="sm" /> : "Save"}
                </Button>
            </ModalBody>
        </Modal>
    );
}

function DocumentDeleteModal({ isOpen, toggle, document, deleteDocument }) {
    const getDocumentName = () => {
        if (!document) return "";
        return document.docName;
    };

    const handleClick = () => {
        if (!document) return;
        deleteDocument(document.docId);
    };

    return (
        <Modal id="document-delete-modal" isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <div>
                    {"Are you sure you want to permanently delete "}
                    <b>{getDocumentName()}</b>
                    {"?"}
                </div>
                <div className="document-delete-modal-buttons">
                    <Button
                        className="document-delete-modal-delete"
                        onClick={handleClick}
                    >
                        Delete
                    </Button>
                    <Button
                        className="document-delete-modal-cancel"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

function DocumentAdminPreview({ doc }) {
    const [document, setDocument] = useState(null);

    useEffect(() => {
        if (doc) {
            setDocument(`${DOC_URL}/preview/${doc.docId}`);
        }
    }, [doc]);

    const renderContent = () => {
        if (!doc) return null;
        if (["png", "jpg", "jpeg", "gif", "bmp"].includes(doc.extension)) {
            return (
                <div id="document-preview-image-wrapper">
                    <img
                        id="document-preview-image"
                        src={document}
                        alt="Preview"
                    />
                </div>
            );
        } else if (["heic", "heif"].includes(doc.extension)) {
            return (
                <div id="document-preview-not-available">
                    <p>Document type not supported by browser.</p>
                </div>
            );
        } else {
            return <iframe title="document-preview-frame" src={document} />;
        }
    };

    return renderContent();
}

export default DocumentAdmin;
