import React, { useContext, useEffect, useState } from "react";
import { Button, Collapse, Input, Label } from "reactstrap";

import { default as utilIcons } from "../../resources/utilIcons";
import { colors } from "../../styles/colors";
import { RenderContext, RestContext } from "../../App";
import { ADMIN_URL } from "../../serverConfig";

function RenderControl() {
    const [elements, setElements] = useState({});
    const [roles, setRoles] = useState([]);
    const [changed, setChanged] = useState(new Set());

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);
    const { refreshRenderControl } = useContext(RenderContext);

    useEffect(() => {
        loadRenderControl();
    }, []);

    const loadRenderControl = () => {
        sendGetRequest(ADMIN_URL + '/renderControl', (response) => {
            changed.clear();
            let elementsData = response.data.elements;
            setElements(elementsData);
            setRoles(response.data.roles);
        });
    }

    const mapElements = () => {
        if (!elements) return null;
        return Object.values(elements).map((element, index) =>
            <ComponentControl key={'component-control-' + index} element={element} roles={roles} handleNotes={handleNotes} handleRole={handleRole} />
        );
    }

    const handleRole = (elementId, roleCode) => {
        changed.add(elementId);

        let element = { ...elements[elementId] };
        if (element.roles.includes(roleCode)) {
            element.roles.splice(element.roles.indexOf(roleCode), 1);
        } else {
            element.roles.push(roleCode);
        }
        setElements(values => ({ ...values, [elementId]: element }));
    }

    const handleNotes = (elementId, notes) => {
        changed.add(elementId);

        let element = { ...elements[elementId] };
        element.notes = notes;
        setElements(values => ({ ...values, [elementId]: element }));
    }

    const canSubmit = () => {
        return changed.size > 0;
    }

    const handleSubmit = () => {
        let payload = {elements: []};
        for (let elementId of changed) {
            let element = elements[elementId];
            element.roles = Array.from(element.roles);
            payload.elements.push(element);
        }

        sendPostRequest(ADMIN_URL + '/renderControl', payload, (response) => {
            loadRenderControl();
            refreshRenderControl();
        })
    }

    return (
        <div id='render-control'>
            <div id='render-control-components-wrapper'>
                {mapElements()}
            </div>
            <div id='render-control-submit-wrapper'>
                <Button id='render-control-submit' className="submit-button" onClick={handleSubmit} disabled={!canSubmit()}>Save</Button>
            </div>
        </div>
    )
}

function ComponentControl({ element, roles, handleNotes, handleRole }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    const hasRole = (roleCode) => {
        if (!element || !element.roles) return false;
        return element.roles.includes(roleCode)
    }

    const mapRoles = () => {
        if (!roles) return null;
        return roles.map((role, index) =>
            <div className="render-control-row-role" key={'render-control-row-role-' + index} onClick={() => handleRole(element.elementId, role.roleCode)}>
                <div className="render-control-row-role-name">{role.roleName}</div>
                <Input type="checkbox" checked={hasRole(role.roleCode)} readOnly />
            </div>
        )
    }

    const getNotes = () => {
        if (!element || !element.notes) return '';
        return element.notes;
    }

    return (
        <div className="render-control-row">
            <div className="render-control-row-header">
                <div className="render-control-row-name">
                    {element.elementName}
                </div>
                <Button className="render-control-row-toggle" onClick={toggleOpen}>{utilIcons.caret(colors['ars-neutral-400'], { rotate: isOpen ? '180deg' : null })}</Button>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="render-control-row-body">
                    <div className="render-control-row-desc">
                        {element.elementDesc}
                    </div>
                    <div className="render-control-row-inputs">
                        <div className="render-control-row-roles-wrapper">
                            <Label for="roles">Roles</Label>
                            <div className="render-control-row-roles" name="roles">
                                {mapRoles()}
                            </div></div>
                        <div className="render-control-row-notes-wrapper">
                            <Label for="notes">Notes</Label>
                            <Input className="render-control-row-notes" name="notes" type="textarea" maxLength={500} value={getNotes()} placeholder="Notes" onChange={(event) => handleNotes(element.elementId, event.target.value)} />
                            <div className='render-control-row-notes-counter'>{getNotes().length + ' / 500'}</div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default RenderControl;