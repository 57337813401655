import moment from "moment";

const timezones = {
    "America/Boise": {
        STD: "MST",
        DST: "MDT",
    },
    "America/Los_Angeles": {
        STD: "PST",
        DST: "PDT",
    },
    "America/Denver": {
        STD: "MST",
        DST: "MDT",
    },
    "America/Chicago": {
        STD: "CST",
        DST: "CDT",
    },
    "America/New_York": {
        STD: "EST",
        DST: "EDT",
    },
    "America/Anchorage": {
        STD: "AKST",
        DST: "AKDT",
    },
    "Pacific/Honolulu": {
        STD: "HST",
        DST: "HDT",
    },
    "America/Phoenix": {
        STD: "MST",
        DST: "MST",
    },
};

export function getTimezoneName(timezone) {
    return timezone.split("/")[1].replace("_", " ");
}

export function getTimezoneAbbr(timezone, standard ) {
    if (standard === undefined) {
        if (moment.tz(new Date(), timezone).isDST()) {
            return timezones[timezone]["DST"];
        } else {
            return timezones[timezone]["STD"];
        }
    } else {
        return standard ? timezones[timezone]["STD"] : timezones[timezone]["DST"];
    }
}

export function today() {
    return new Date().toISOString().split("T")[0];
}

export function last30() {
    return new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .split("T")[0];
}

export function last90() {
    return new Date(new Date().setDate(new Date().getDate() - 90))
        .toISOString()
        .split("T")[0];
}

export function formatDate(time) {
    const dateString = new Date(time).toLocaleString();
    const dateArray = dateString.split(/[,:/\s]+/g);
    return `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
}

export function formatDatetime(time) {
    const dateString = new Date(time).toLocaleString();
    const dateArray = dateString.split(/[,:/\s]+/g);
    return `${dateArray[3]}:${dateArray[4]} ${dateArray[6]}, ${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
}

export function formatDatetimeWithLocalTimezone(time) {
    const dateString = new Date(time).toLocaleString();
    const dateArray = dateString.split(/[,:/\s]+/g);
    return `${dateArray[3]}:${dateArray[4]} ${dateArray[6]} ${
        getTimezoneAbbr(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }, ${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
}
